import { requestToken } from '@api/axios';
import API_URL from '@api/url';
import IStatisticCreated from '@assets/images/created-svg.svg';
import IStatisticDone from '@assets/images/done-svg.svg';
import IStatisticExpired from '@assets/images/expired-svg.svg';
import IStatisticFailed from '@assets/images/failed-svg.svg';
import React, { useEffect, useState } from 'react';
import { StatisticStyle } from './style/StatisticStyle';

export default function LinkStatistic() {
  const [state, setState] = useState({
    totalCreated: 0,
    totalPaid: 0,
    totalCanceled: 0,
    totalExpired: 0,
  });

  useEffect(() => {
    requestToken({
      method: 'GET',
      url: API_URL.DASHBOARD.LINK_STATISTIC,
    }).then(res => setState(res.data));
  }, []);

  return (
    <StatisticStyle>
      <div className="container">
        <p className="header">Thống kê đơn hàng</p>
        <div className="content-container">
          <div className="item-container">
            <span className="icon">
              <img src={IStatisticCreated} alt="icon" />
            </span>
            <div className="text-container">
              <p className="value">{state.totalCreated}</p>
              <p className="text created">Đơn hàng đã tạo</p>
            </div>
          </div>
          <div className="item-container">
            <span className="icon">
              <img src={IStatisticDone} alt="icon" />
            </span>
            <div className="text-container">
              <p className="value">{state.totalPaid}</p>
              <p className="text paid">Đơn hàng thanh toán thành công</p>
            </div>
          </div>
          <div className="item-container">
            <span className="icon">
              <img src={IStatisticExpired} alt="icon" />
            </span>
            <div className="text-container">
              <p className="value">{state.totalExpired}</p>
              <p className="text expired">Đơn hàng bị hết hạn</p>
            </div>
          </div>
          <div className="item-container">
            <span className="icon">
              <img src={IStatisticFailed} alt="icon" />
            </span>
            <div className="text-container">
              <p className="value">{state.totalCanceled}</p>
              <p className="text canceled">Đơn hàng thất bại</p>
            </div>
          </div>
        </div>
      </div>
    </StatisticStyle>
  );
}
