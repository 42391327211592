import styled from 'styled-components';

export const Content = styled.div`
  padding: 28px 58px 36px;
  height: 100%;

  @media (max-width: 991px) {
    padding: 15px;
  }
  @media (max-width: 767px) {
    padding: 5px;
    min-height: unset;
  }
`;
