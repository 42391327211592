import ErrorBound from '@components/ErrorBound';
import HomeLayout from '@containers/CLayout/LayoutNews/HomeLayout';
import React from 'react';
import { Container } from 'styled-bootstrap-grid';
import WrapPayDetail from './style';
import IPay1 from '@assets/images/mobeos/instruction/pay/pic1.png';
import IPay2 from '@assets/images/mobeos/instruction/pay/pic2.png';
import IPay3 from '@assets/images/mobeos/instruction/pay/pic3.png';
import IPay4 from '@assets/images/mobeos/instruction/pay/pic4.png';
import IPay5 from '@assets/images/mobeos/instruction/pay/pic5.png';
import IPay6 from '@assets/images/mobeos/instruction/pay/pic6.png';
import IPay7 from '@assets/images/mobeos/instruction/pay/pic7.png';

export default function PayDetail() {
  return (
    <HomeLayout>
      <ErrorBound>
        <WrapPayDetail>
          <Container>
            <div className="wrap_inside">
              <div className="title">Hướng dẫn sử dụng chi tiết</div>

              <div className="title-wrapper">
                <h4>004</h4>
                <h5>Hướng dẫn thanh toán đơn hàng</h5>
              </div>

              <div className="steps">
                <div className="step_item">
                  <div className="step_item_detail">
                    <div className="step_name">
                      <p>Bước 1: Vào Cửa hàng</p>
                      <div className="underline"></div>
                    </div>
                  </div>
                  <img src={IPay1} alt="payment" />
                </div>

                <div className="step_item">
                  <div className="step_item_detail">
                    <div className="step_name">
                      <p>Bước 2: Chọn sản phẩm yêu thích</p>
                      <div className="underline"></div>
                    </div>
                  </div>
                  <img src={IPay2} alt="payment" />
                </div>

                <div className="step_item">
                  <div className="step_item_detail">
                    <div className="step_name">
                      <p>Bước 3: Xem thông tin chi tiết sản phẩm</p>
                      <div className="underline"></div>
                    </div>
                  </div>
                  <img src={IPay3} alt="payment" />
                </div>

                <div className="step_item">
                  <div className="step_item_detail">
                    <div className="step_name">
                      <p>
                        Bước 4: Thực hiện mua ngay hoặc thêm sản phẩm vào giỏ
                        hàng
                      </p>
                      <div className="underline"></div>
                    </div>
                  </div>
                  <img src={IPay4} alt="payment" />
                </div>

                <div className="step_item">
                  <div className="step_item_detail">
                    <div className="step_name">
                      <p>
                        Bước 5: Nhập thông tin người mua tại giỏ hàng và tiến
                        hành thanh toán
                      </p>
                      <div className="underline"></div>
                    </div>
                  </div>
                  <img src={IPay5} alt="payment" />
                </div>

                <div className="step_item">
                  <div className="step_item_detail">
                    <div className="step_name">
                      <p>
                        Bước 6: Kiểm tra thông tin đơn hàng & chọn cổng thanh
                        toán
                      </p>
                      <div className="underline"></div>
                    </div>
                  </div>
                  <img src={IPay6} alt="payment" />
                </div>

                <div className="step_item">
                  <div className="step_item_detail">
                    <div className="step_name">
                      <p>
                        Bước 7: Xác nhận thông tin thanh toán để chuyển qua kênh
                        thanh toán
                      </p>
                      <div className="underline"></div>
                    </div>
                  </div>
                  <img src={IPay7} alt="payment" />
                </div>
              </div>
            </div>
          </Container>
        </WrapPayDetail>
      </ErrorBound>
    </HomeLayout>
  );
}
