import Button from '@components/Button';
import { TSelect } from '@configs/types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { handleExport } from './store/actions';
import { selectProductsStore } from './store/selecters';
import { SHandle } from './style';

import ModalProduct from './ModalProduct';

export const currencies: TSelect[] = [
  { label: 'VND', value: 'VND' },
  // { label: 'USD', value: 'USD' },
];

export default function Handle() {
  const [isShowing, toggleModal] = useState(false);
  const { search } = useSelector(selectProductsStore);

  const onExport = async () => {
    await handleExport({
      keyword: search.keyword,
      status: search.status,
    });
  };

  return (
    <SHandle>
      <Button className="add" onClick={() => toggleModal(!isShowing)}>
        Tạo mới
      </Button>
      <Button className="print" onClick={onExport}>
        Export
      </Button>
      {isShowing && <ModalProduct open={isShowing} setOpen={toggleModal} />}
    </SHandle>
  );
}
