import Modal from '@components/Modal';
import Table from '@components/Table';
import { convertDate, convertDateWithTime } from '@helpers/format';
import React, { useState } from 'react';
import { SModal } from './style';
import * as format from '@helpers/format';
import { TypeWallet } from '@containers/Withdrawal/Withdr';

export default function DataTable({
  data,
  loading,
}: {
  data: any[];
  loading: boolean;
}) {
  const [detail, setDetail] = useState<any | null>(null);

  const THead = [
    'Mã giao dịch',
    'Số tiền rút',
    'Phí giao dịch',
    'Luồng tiền',
    'Thời gian yêu cầu',
    'Trạng thái',
  ];

  return (
    <>
      <Table
        isLoading={loading}
        style={{ border: false, minWidth: 900 }}
        thead={THead.map((h, index) => (
          <th key={'thead' + index}>{h}</th>
        ))}
        tbody={data.map((d, index) => {
          return (
            <tr
              key={index}
              onClick={() => setDetail({ ...d })}
              style={{ userSelect: 'none', cursor: 'pointer' }}
            >
              <td>{d?.code}</td>
              <td>{format.money(d?.money, undefined, true)}</td>
              <td>
                {format.money(
                  Number(d?.minusMoney) - Number(d?.money) || 0,
                  undefined,
                  true,
                )}
              </td>
              <td>
                {d?.gatewayWithdraw === TypeWallet.local
                  ? 'Nội địa'
                  : 'Quốc tế'}
              </td>
              <td>{convertDate(d?.dateRequest)}</td>
              <td>
                <span className={d?.status}>
                  {format.convertStatusWithDraw(d?.status)}
                </span>
              </td>
            </tr>
          );
        })}
      />

      {detail && (
        <Modal
          isShowing={detail ? true : false}
          title="Chi tiết giao dịch"
          toggleModal={() => setDetail(null)}
          overwriteChild={{ style: { maxWidth: '720px' } }}
        >
          <SModal>
            <div className="modal-field">
              <span>Mã giao dịch</span>
              <span>{detail?.code}</span>
            </div>
            <div className="modal-field">
              <span>Số tiền</span>
              <span>{format.money(detail?.money, undefined, true)}</span>
            </div>
            <div className="modal-field">
              <span>Phí giao dịch</span>
              <span>
                {format.money(
                  Number(detail?.minusMoney) - Number(detail?.money) || 0,
                  undefined,
                  true,
                )}
              </span>
            </div>
            <div className="modal-field">
              <span>Chủ tài khoản</span>
              <span>{detail?.merchantBank?.holder}</span>
            </div>
            <div className="modal-field">
              <span>Ngân hàng</span>
              <span>{detail?.merchantBank?.bank?.name}</span>
            </div>
            <div className="modal-field">
              <span>Thời gian giao dịch</span>
              <span>
                {detail?.dateConfirm &&
                  convertDateWithTime(detail?.dateConfirm)}
              </span>
            </div>
            <div className="modal-field">
              <span>Thời gian yêu cầu</span>
              <span>{convertDateWithTime(detail?.dateRequest)}</span>
            </div>
            <div className="modal-field">
              <span>Trạng thái</span>
              <span>{format.convertStatusWithDraw(detail?.status)}</span>
            </div>
            <div className="modal-field">
              <span>Ghi chú</span>
              <span>{detail?.reasonOther || ''}</span>
            </div>
          </SModal>
        </Modal>
      )}
    </>
  );
}
