/**
 *
 * Text
 * make by phamthainb
 */
import styled from 'styled-components';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import theme from '@styles/theme';
import React from 'react';
import { text } from '@styles/mixins';

export const Warning = styled.p``;

export const SError = styled.p`
  ${text.sm};
  color: ${theme.color.red};
  margin: 8px 0;
`;

export const ErrorMessage = ({
  message,
  messages,
}: {
  message: string;
  messages?: import('react-hook-form').MultipleFieldErrors | undefined;
}): any => <SError>{message}</SError>;
