import Table from '@components/Table';
import Pagination from 'rc-pagination';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTableRow from './DataTableRow';
import { changePage } from './store/actions';
import { selectOrderStore } from './store/selecters';
import ShowSelect from '@components/Input/ShowSelect';

const THead = [
  'Mã đơn hàng',
  'Thời gian yêu cầu',
  'Giảm giá',
  'Phí giao dịch',
  'Tổng tiền thanh toán',
  'Trạng thái',
];

export default function DataTable({ loading }: { loading: boolean }) {
  const { data, pagination, total } = useSelector(selectOrderStore);
  const dispatch = useDispatch();

  return (
    <>
      <Table
        style={{ border: true }}
        isLoading={loading}
        thead={THead.map((h, index) => (
          <th key={'thead' + index}>{h}</th>
        ))}
        tbody={data.map((d: any) => {
          return <DataTableRow product={d} />;
        })}
      />
      {(total || 0) / pagination.pageSize >= 1 && (
        <div className="pagination-container">
          <div className="select">
            <label>
              Hiển thị từ{' '}
              {`${
                (pagination.pageNumber - 1) * pagination.pageSize + 1
              } đến ${Math.ceil(
                pagination.pageSize * pagination.pageNumber,
              )}`}{' '}
              trên tổng số {total}
            </label>
            <ShowSelect
              defaultValue={{
                label: pagination.pageSize.toString(),
                value: pagination.pageSize.toString(),
              }}
              onChange={val => {
                let pageSize = pagination.pageSize;
                if (val && parseInt(val.value) !== pageSize) {
                  pageSize = parseInt(val.value);
                  dispatch(changePage({ pageNumber: 1, pageSize }));
                }
              }}
            />
          </div>

          <Pagination
            onChange={(pageNumber: number, pageSize: number) => {
              dispatch(changePage({ ...pagination, pageNumber, pageSize }));
            }}
            pageSize={pagination.pageSize}
            current={pagination.pageNumber}
            total={total}
          />
        </div>
      )}
    </>
  );
}
