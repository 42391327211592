import { Button, Layout } from '@components/index';
import React from 'react';
// import images
import { ReactComponent as Close } from '@assets/images/icon-close.svg';
import ILogo from '@assets/images/logo_secondary.svg';
import ROUTES, { ItemRoute } from '@configs/routes';
import { selectAppStore } from '@containers/App/store/selecters';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useMediaQuery from '@hooks/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { NavLink, useLocation } from 'react-router-dom';
import DropInforUser from './DropInforUser';
import Notification from './Notification';

export default function Header() {
  const [show, setShow] = React.useState(false);
  const { width } = useMediaQuery();
  const dispatch = useDispatch();
  const { ui, user } = useSelector(selectAppStore);
  const history = useHistory();
  console.log(show);
  
  return (
    <Layout.Header>
      <section className="header-top">
        <div
          className="logo"
          onClick={() => {
            history.push('/');
          }}
        >
          <img src={ILogo} alt="logo" />
        </div>
        <div className="info-user">
          <Notification />

          <DropInforUser />
          {width < 992 && (
            <Button
              style={{ padding: '0 20px' }}
              onClick={() => {
                setShow(!show);
              }}
              
            >
              <FontAwesomeIcon icon={faBars} />
            </Button>
          )}
        </div>
      </section>

      <section className="header-bottom">
        <div className="greeting">
          <h2 className="hello">
            Xin chào{' '}
            <span>
              {user.firstName} {user.lastName}!
            </span>
          </h2>
          <p>Chào mừng bạn đến với Mobeos</p>
        </div>

        <div className={`nav-wrapper ${show ? ' show_sidebar' : ''}`}>
          <span
            className="close"
            onClick={() => {
              setShow(!show);
            }}
          >
            <Close />
          </span>
          <div className="nav">
            {ROUTES.DASHBROAD.map((item, index) => (
              <NavLinkCustom key={index} {...item} />
            ))}
          </div>
        </div>
      </section>
    </Layout.Header>
  );
}

const NavLinkCustom = (props: ItemRoute) => {
  const { name, path, icon } = props;
  const { pathname } = useLocation();

  if (path) {
    return (
      <NavLink
        exact
        className={'nav-item'}
        activeClassName="active"
        to={path}
        style={{ textDecoration: 'none' }}
      >
        {name}
      </NavLink>
    );
  } else {
    // has child
    return <NavHasChild {...props} />;
  }
};

const NavHasChild = ({ name, path, icon, child }: ItemRoute) => {
  const { pathname } = useLocation();
  const pathChilds = child?.map(c => c.path);

  return (
    <div
      className={`dropdown-item ${
        pathChilds?.includes(pathname) ? 'active' : ''
      }`}
    >
      <div className="nav-item">{name}</div>
      <div className="wrapper">
        <div className="child-item" style={{ transitionDelay: '0.3s' }}>
          {child &&
            child.map(
              (c, j) =>
                c.path && (
                  <NavLink
                    key={j}
                    className="child-item__item"
                    activeClassName="active"
                    to={c?.path}
                  >
                    {c.name}
                  </NavLink>
                ),
            )}
        </div>
      </div>
    </div>
  );
};
