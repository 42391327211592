import theme from '@styles/theme';
import React, { ReactChild, ReactChildren } from 'react';
import styled from 'styled-components';
import { mixinAbsolute, mixinsFlexCenter } from '@styles/mixins';

//icon
import IBack from '@assets/images/mobeos/dashboard/back.svg';
import { useHistory } from 'react-router';

interface Props {
  title: string;
  children: ReactChild | ReactChildren;
  handle?: ReactChild | ReactChildren;
}
export default function Section({ children, title, handle }: Props) {
  const history = useHistory();
  return (
    <StyleSection>
      <div className="head-content">
        <h2 className="title">
          {title}
          <img
            src={IBack}
            alt="back"
            className="icon-back"
            onClick={() => history.goBack()}
          />
        </h2>
        <div>{handle}</div>
      </div>
      <div className="content-section">{children}</div>
    </StyleSection>
  );
}

export const StyleSection = styled.div`
  border-radius: 12px;
  .head-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  h2.title {
    font-family: ${theme.fonts['Inter-Medium']};
    /* font-weight: 500; */
    font-size: 24px;
    line-height: 32px;
    color: ${theme.color.dark};
    mix-blend-mode: normal;
    position: relative;
    padding-left: 44px;
    margin: 0;
    ${mixinsFlexCenter};
    justify-content: start;
    /* min-height: 80px; */
    background: transparent;
    margin-bottom: 20px;

    .icon-back {
      ${mixinAbsolute};
      left: 0px;
      cursor: pointer;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .content-section {
    padding: 20px 44px;
    background: ${theme.color.rose_white};
    border-radius: 12px;
  }

  @media screen and (max-width: 991px) {
    .content-section {
      padding: 40px 20px;
    }
    h2.title {
      padding-left: 56px;
      .icon-back {
        left: 20px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .content-section {
      padding: 20px 15px;
    }
    h2.title {
      padding-left: 44px;
      .icon-back {
        left: 15px;
      }
    }
  }
`;
