import ShowSelect from '@components/Input/ShowSelect';
import Modal from '@components/Modal';
import Pagination from '@components/Paginations';
import Table from '@components/Table';
import * as format from '@helpers/format';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import DetailPayment from './DetailPayment';
import ModalChangeStatus from './ModalChangeStatus';
import ModalRefund from './ModalRefund';
import ModalSupport from './ModalSupport';
import { changePage, reloadData } from './store/actions';
import { selectPaymentLinksStore } from './store/selecters';

export const CTHead = [
  'Mã đơn hàng',
  'Kênh thanh toán',
  'Số tiền',
  'Ngày tạo',
  'Trạng thái thanh toán',
  'Trạng thái Đơn hàng',
  'Yêu cầu xử lý',
  'Đơn hàng',
];

export default function DataTable({ loading }: { loading: boolean }) {
  const { pagination, data, total } = useSelector(selectPaymentLinksStore);
  const dispatch = useDispatch();
  const [showChangeStatus, setShowChangeStatus] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [showRefund, setShowRefund] = useState<boolean>(false);
  const [showSupport, setshowSupport] = useState<boolean>(false);
  const [picker, setPicker] = useState<any>(null);
  return (
    <STable>
      <Table
        style={{ border: true }}
        isLoading={loading}
        thead={CTHead.map((t: any, i: number) => {
          return (
            <th
              style={{
                minWidth: '120px',
                textAlign: 'left',
                color: '#8E95A7',
              }}
            >
              {t}
            </th>
          );
        })}
        tbody={data.map((p, index) => {
          return (
            <tr
              key={index}
              style={{
                color: p.paid === 0 || p.paid === null ? '#828282' : '#3A3B42',
                cursor: 'pointer',
                textAlign: 'left',
              }}
              onClick={e => {
                e.preventDefault();
                setPicker(p);
                setShowDetails(!showDetails);
              }}
            >
              <td>{p.codeBill}</td>
              <td>{p.transactionsGateway}</td>
              <td>{format.money(p.money, undefined, true)}</td>
              <td>{new Date(p.createdAt).toLocaleDateString('vi-VN')}</td>
              <td>
                {p.paid === null
                  ? 'Đang chờ xử lý'
                  : p.paid === 0
                  ? 'Thất bại'
                  : 'Thành công'}
              </td>
              <td>
                <p className={`${p.statusDetail}`}>
                  {format.convertStatus(p.statusDetail)}
                </p>
              </td>
              <td>
                {p?.supportId ? 'Yêu cầu hỗ trợ \n' : ''}
                {p?.refundId ? 'Hoàn tiền' : ''}
              </td>
              <td>{p.url}</td>
            </tr>
          );
        })}
      />

      {(total || 0) / pagination.pageSize >= 1 && (
        <div className="pagination-container">
          <div className="select">
            <label>
              Hiển thị từ{' '}
              {`${
                (pagination.pageNumber - 1) * pagination.pageSize + 1
              } đến ${Math.ceil(
                pagination.pageSize * pagination.pageNumber,
              )}`}{' '}
              trên tổng số {total}
            </label>
            <ShowSelect
              defaultValue={{
                label: pagination.pageSize.toString(),
                value: pagination.pageSize.toString(),
              }}
              onChange={val => {
                let pageSize = pagination.pageSize;
                if (val && parseInt(val.value) !== pageSize) {
                  pageSize = parseInt(val.value);
                  dispatch(changePage({ pageNumber: 1, pageSize }));
                }
              }}
            />
          </div>

          <Pagination
            onChange={(pageNumber: number, pageSize: number) => {
              dispatch(changePage({ pageNumber, pageSize }));
            }}
            pageSize={pagination.pageSize}
            current={pagination.pageNumber}
            total={total}
          />
        </div>
      )}

      {/* modal */}
      {picker && (
        <>
          {/* Chi tiết Đơn hàng */}
          <Modal
            title="Chi tiết Đơn hàng"
            isShowing={showDetails}
            toggleModal={() => setShowDetails(!showDetails)}
            overwriteChild={{ style: { maxWidth: '720px' } }}
          >
            <DetailPayment
              data={picker}
              changeStatus={() => {
                // setPicker(p);
                setShowChangeStatus(!showChangeStatus);
              }}
              toggle={() => setShowDetails(!showDetails)}
            />
          </Modal>

          {/* Thay đổi trạng thái */}
          <Modal
            title="Thay đổi trạng thái"
            isShowing={showChangeStatus}
            toggleModal={() => setShowChangeStatus(!showChangeStatus)}
            overwriteChild={{ style: { maxWidth: '650px' } }}
          >
            <ModalChangeStatus
              data={picker}
              toggle={() => setShowChangeStatus(!showChangeStatus)}
              callback={() => {
                dispatch(reloadData());
                setShowDetails(!showDetails);
              }}
            />
          </Modal>

          {/* Hoàn tiền */}
          <Modal
            title="Hoàn tiền"
            isShowing={showRefund}
            toggleModal={() => setShowRefund(!showRefund)}
            overwriteChild={{ style: { maxWidth: '650px' } }}
          >
            <ModalRefund
              data={picker}
              setShowRefund={setShowRefund}
              callback={() => {
                dispatch(reloadData());
              }}
            />
          </Modal>

          {/* Yêu cầu hỗ trợ */}
          <Modal
            title="Yêu cầu hỗ trợ"
            isShowing={showSupport}
            toggleModal={() => setshowSupport(!showSupport)}
            overwriteChild={{ style: { maxWidth: '650px' } }}
          >
            <ModalSupport
              data={picker}
              setOpen={setshowSupport}
              callback={() => {
                dispatch(reloadData());
              }}
            />
          </Modal>
        </>
      )}
    </STable>
  );
}

const SMoreAction = styled.span`
  display: flex;
`;
const STable = styled.div`
  .pagination-container {
    padding: 16px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    label {
      color: #8e95a7;
    }
    .select {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
`;
