/**
 *
 * Button
 *
 */
import styled, { css } from 'styled-components';
import theme from '@styles/theme';
import { text } from '@styles/mixins';

const color = {
  green: {
    text: theme.color.white,
    bgr: theme.color.green,
  },
  gray: {
    text: '#6E7B97',
    bgr: '#E4EFFA',
  },
  red: {
    text: 'white',
    bgr: 'red',
  },
};

type Props = {
  color?: 'green' | 'gray' | 'red';
  backgroundColor?: string;
  variant?: 'outline' | 'ghost';
};

const Button = styled.button<Props>`
  border-radius: 12px;
  height: 48px;
  font-family: ${theme.fonts['Inter-Medium']};
  ${text.base};
  text-align: center;
  color: ${props => color[props.color || 'green'].text};
  background: ${theme.color.linear_purple};
  /* background: ${props => color[props.color || 'green'].bgr}; */
  /* background-color: ${props =>
    props.backgroundColor || color[props.color || 'green'].bgr}; */
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0 30px;
  transition: all 0.25s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: auto;
  ${({ variant }) =>
    variant === 'ghost' &&
    css`
      background: transparent;
      color: ${theme.color.text_secondary};
      gap: 4px;
    `}

  ${({ variant }) =>
    variant === 'outline' &&
    css`
      background: transparent;
      border: 1px solid #de58fd;
      color: ${theme.color.dark};

      &:hover {
        background: ${theme.color.linear_purple};
        color: ${theme.color.white};
        box-shadow: none !important;
        svg {
          path {
            fill: ${theme.color.white};
          }
        }
      }
    `}

  &:hover {
    box-shadow: 2px 2px 8px 0px rgb(0, 0, 0, 0.3);
  }
  &:disabled {
    cursor: not-allowed;
  }

  @media screen and (max-width: 1199px) {
    ${text.sm};
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export default Button;
