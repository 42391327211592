import Button from '@components/Button';
import Input from '@components/Input';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Text } from '@components/index';
import VALIDATE from '@helpers/validate';
import HookForm from '@components/Input/HookForm';
import { selectAppStore } from '@containers/App/store/selecters';
import { useDispatch, useSelector } from 'react-redux';
import { getBanks, setBanks, setTransactionId } from './store/actions';
import NumberFormat from 'react-number-format';
import { HandleError } from '@components/HandleResponse';
import { selectWithdrawalStore } from './store/selecters';
import { requestInterToken } from '@api/axios';
import API_URL from '@api/url';
import { Alert } from '@components/Alert';
import * as format from '@helpers/format';

export enum TypeWallet {
  local = 'local',
  global = 'global',
}

const MONEY = {
  MIN: 200000,
  MAX: 50000000,
};

// type value => api caculate-fee => api/withdraw => /merchant/create-otp => merchant/check-otp
export default function WithdrLocal({ toggleModal1, triggerReset }: any) {
  const dispatch = useDispatch();

  const { handleSubmit, errors, control, getValues, reset } = useForm();

  const { banks } = useSelector(selectWithdrawalStore);
  const { user } = useSelector(selectAppStore);

  const [state, setState] = useState<any>({
    local: {
      withdraw: 0,
      fee: 0,
      total: 0,
      program: '',
      open: false,
    },
    global: {
      withdraw: 0,
      fee: 0,
      total: 0,
      program: '',
      open: false,
    },
  });

  const onChangeDisplay = (type: TypeWallet) => {
    const s = { ...state };
    if (type === TypeWallet.local) {
      s['local'].open = true;
      s['global'].open = false;
    } else {
      s['local'].open = false;
      s['global'].open = true;
    }
    setState(s);
  };

  useEffect(() => {
    getBanks().then(res =>
      dispatch(setBanks(res.data.data, res.data.meta.total)),
    );
    // .catch(HandleError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSumitLocal = (data: any) => {
    onSumit(data);
  };

  const onSumit = (data: any) => {
    console.log('data', data);

    const type = state.local.open ? TypeWallet.local : TypeWallet.global;

    if (`${state[type].withdraw}`.match(/^[1-9]{1}[0-9]*000$/gm)) {
      // bội của 1.000
      requestInterToken({
        method: 'POST',
        url: API_URL.WITHDRAW.POST,
        data: {
          fee: state[type].fee,
          money: state[type].withdraw,
          merchantBankId: data[type].select?.value ?? '',
          feeProgram: state[type].program,
        },
      })
        .then(res => {
          toggleModal1();
          dispatch(setTransactionId(res.data.id)); // ?? for what
        })
        .catch(HandleError);
    } else
      Alert({ name: 'Số tiền phải là bội số của 1,000 vnđ', icon: 'error' });
  };

  const getFee = async (type: TypeWallet) => {
    let money = getValues(`${type}.withdraw`) as any;

    if (money >= MONEY.MIN && money <= MONEY.MAX) {
      await requestInterToken({
        method: 'POST',
        url: API_URL.WITHDRAW.GET_FEE,
        data: { money: money, gatewayWithdraw: type },
      })
        .then(res => {
          const feeValue =
            Number(res.data.fee.moneyWithdraw - money).toFixed(3) || '0';

          let s = { ...state };
          s[type].withdraw = money;
          s[type].total = res.data.fee.moneyWithdraw;
          s[type].fee = parseFloat(feeValue);
          s[type].program = res.data.withdrawFeeProgram;

          setState(s);
        })
        .catch(HandleError);
    }
  };

  const wallet = user?.wallet ?? [];
  const { local } = state;

  // console.log('state', state, default_state);

  useEffect(() => {
    reset();
    setState({
      local: {
        withdraw: 0,
        fee: 0,
        total: 0,
        program: '',
        open: false,
      },
      global: {
        withdraw: 0,
        fee: 0,
        total: 0,
        program: '',
        open: false,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerReset]);

  return (
    <>
      <div className="left">
        <h3 className="title">
          Số dư thẻ nội địa:{' '}
          <span className="balance">
            {format.money(wallet[0]?.money ?? 0, undefined, true)}
          </span>
        </h3>
        {/* <div className="money_number">
          <NumberFormat
            customInput={Input}
            disabled
            value={wallet[0]?.money ?? 0}
            thousandSeparator
            min={0}
          />
          <div className="unit">VNĐ</div>
        </div> */}
        <form
          className="form-withdr"
          // style={{ margin: !local.open ? '20px 0 0 0' : '38px 0 0 0' }}
          onSubmit={handleSubmit(onSumitLocal)}
        >
          <div style={{ display: local.open ? 'block' : 'none' }}>
            <>
              <label className="field-input">
                <p className="text">Số tiền cần rút</p>
                <div
                  className="infor_withdr"
                  onBlur={() => {
                    if ((getValues('local.withdraw') as any) > 0)
                      getFee(TypeWallet.local);
                  }}
                >
                  <HookForm.InputNumberFormat
                    controller={{
                      name: 'local.withdraw',
                      control: control,
                      render: data => <span>data</span>,
                      defaultValue: 0,
                      rules: {
                        min: {
                          value: MONEY.MIN,
                          message: 'Nhỏ nhất 200,000 vnđ',
                        },
                        max: {
                          value: MONEY.MAX,
                          message: 'Tối đa 50,000,000 vnđ',
                        },
                        required: VALIDATE.REQUIRE,
                      },
                    }}
                    numberFormat={{
                      thousandSeparator: true,
                    }}
                  />
                </div>
              </label>
              <ErrorMessage
                errors={errors}
                name={'local.withdraw'}
                render={Text.ErrorMessage}
              />
            </>
            <>
              <label className="field-input">
                <p className="text">Phí rút tiền</p>
                <div className="infor_withdr">
                  <NumberFormat
                    value={local.fee}
                    customInput={Input}
                    thousandSeparator={true}
                    disabled={true}
                  />
                </div>
              </label>
            </>
            <>
              <label className="field-input">
                <p className="text">Tổng tiền </p>
                <div className="infor_withdr">
                  <NumberFormat
                    value={local.total}
                    customInput={Input}
                    thousandSeparator={true}
                    disabled={true}
                  />
                </div>
              </label>
            </>
            <>
              <div className="field-input">
                <p className="text">Tài khoản nhận tiền</p>
                <div className="infor_withdr">
                  <HookForm.InputSelect
                    controller={{
                      control: control,
                      name: 'local.select',
                      render: data => <span>input select</span>,
                      rules: { required: VALIDATE.REQUIRE },
                    }}
                    select={{
                      placeholder: 'Chọn tài khoản ngân hàng',
                      options: banks.list.map((l, index) => {
                        return {
                          label: `${l.label} - ${l.number}`,
                          value: l.id,
                        };
                      }),
                    }}
                  />
                </div>
              </div>
              <ErrorMessage
                errors={errors}
                name="local.select"
                render={Text.ErrorMessage}
              />
            </>
          </div>

          {!local.open ? (
            <Button
              // disabled
              className="final_button"
              type="button"
              onClick={e => {
                e.preventDefault();
                if (wallet[0]?.money > 0) {
                  onChangeDisplay(TypeWallet.local);
                } else {
                  Alert({
                    icon: 'warning',
                    name: 'Số dư của bạn không đủ.',
                  });
                }
              }}
            >
              Rút tiền
            </Button>
          ) : (
            <Button
              //  disabled
              className="final_button finalBT"
              type="submit"
            >
              Tiếp tục
            </Button>
          )}
          {/* <Text.ErrorMessage message="Tính năng rút tiền hiện đang bảo trì. Vui lòng quay lại sau."></Text.ErrorMessage> */}
        </form>
      </div>
    </>
  );
}
