import styled from 'styled-components';

export const StatisticStyle = styled.div`
  .header {
    font-size: 24px;
    color: #0b0c17;
    line-height: 36px;
    font-weight: 500;
  }
  .content-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    p {
      margin: 10px 0 0 0;
    }
    .item-container {
      padding: 20px;
      background: #fff;
      border-radius: 12px;
      width: 184px;
      .text-container {
        margin-top: 60px;
        .value {
          font-size: 24px;
          font-weight: 500;
          line-height: 32px;
          color: #0b0c17;
        }
        .text {
          font-size: 14px;
          line-height: 20px;
        }
        .created {
          background: -webkit-linear-gradient(#de58fd, #0086ff);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .paid {
          color: #00bc6c;
        }
        .expired {
          color: #8e95a7;
        }
        .canceled {
          color: #b92200;
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .container {
      .content-container {
        .item-container {
          width: 100%;
        }
      }
    }
  }
  @media screen and (max-width: 666px) {
    .container {
      .content-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
  }
`;
