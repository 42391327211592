import React, { ReactChild, ReactChildren, useEffect } from 'react';
import { Layout } from '@components/index';

import SideBar from './SideBar';
import { requestToken } from '@api/axios';
import API_URL from '@api/url';
import { HandleError } from '@components/HandleResponse';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '@containers/App/store/actions';
import Header from './Header';
import { selectAppStore } from '@containers/App/store/selecters';
import useScrollTop from '@hooks/useScrollTop';

interface Props {
  children: ReactChild | ReactChildren;
}

const LayoutDashBroad = ({ children }: Props) => {
  const dispatch = useDispatch();
  const { onRefresh } = useSelector(selectAppStore);

  useEffect(() => {
    // const auth = JSON.parse(localStorage.getItem('auth') || 'false');
    requestToken({ method: 'GET', url: API_URL.MERCHANT.PROFILE })
      .then(res => {
        let mer = {...res.data.merchant}
        mer.fullName = mer.firstName + ' ' + mer.lastName;
        mer.wallet = [...res.data.merchantBalance]

        dispatch(setUser(mer));
      })
      .catch(HandleError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onRefresh]);

  useScrollTop();
  return (
    <Layout.Wrap>
      <Header />
      <Layout.Body>
        {/* <SideBar /> */}
        <Layout.Content>{children}</Layout.Content>
      </Layout.Body>
    </Layout.Wrap>
  );
};

export default LayoutDashBroad;
