import { HandleError } from '@components/HandleResponse';
import ShowSelect from '@components/Input/ShowSelect';
import Pagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from './DataTable';
import { getData, setData, setPaging } from './store/actions';
import { selectTransactionListStore } from './store/selecters';
import { SSelectedTab } from './style';

export default function TransactionTab({ type }: { type: string }) {
  const { pagination, data, total, search } = useSelector(
    selectTransactionListStore,
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getData({ pagination, type, search })
      .then(res => {
        dispatch(setData({ data: res.data, total: res.data.meta.total }));
        setLoading(false);
      })
      .catch(HandleError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, search]);

  return (
    <SSelectedTab>
      <DataTable data={data} loading={loading} />
      {(total || 0) / pagination.pageSize >= 1 && (
        <div className="pagination-container">
          <div className="select">
            <label>
              Hiển thị từ{' '}
              {`${
                (pagination.pageNumber - 1) * pagination.pageSize + 1
              } đến ${Math.ceil(
                pagination.pageSize * pagination.pageNumber,
              )}`}{' '}
              trên tổng số {total}
            </label>
            <ShowSelect
              defaultValue={{
                label: pagination.pageSize.toString(),
                value: pagination.pageSize.toString(),
              }}
              onChange={val => {
                let pageSize = pagination.pageSize;
                if (val && parseInt(val.value) !== pageSize) {
                  pageSize = parseInt(val.value);
                  dispatch(setPaging({ pageNumber: 1, pageSize }));
                }
              }}
            />
          </div>

          <Pagination
            onChange={(pageNumber: number) => {
              dispatch(setPaging({ ...pagination, pageNumber }));
            }}
            current={pagination.pageNumber}
            pageSize={pagination.pageSize}
            total={total}
          />
        </div>
      )}
    </SSelectedTab>
  );
}
