import { text } from '@styles/mixins';
import theme from '@styles/theme';
import styled from 'styled-components';

const WrapPayDetail = styled.div`
  padding: 156px 0 162px;
  background: linear-gradient(53.78deg, #ffffff 53.2%, #97ceff 159.3%);

  p {
    margin: unset;
  }

  .wrap_inside {
    box-sizing: border-box;

    .title {
      font-family: ${theme.fonts['Inter-Medium']};
      font-size: 48px;
      line-height: 52px;
      color: ${theme.color.blue_science};
      text-align: center;
      margin-bottom: 56px;
    }

    .title-wrapper {
      margin-bottom: 40px;
      position: relative;
      h4 {
        font-family: ${theme.fonts['Inter-Regular']};
        font-size: 80px;
        line-height: 52px;
        color: #e6ebf1;
        margin: 0;
      }
      h5 {
        position: absolute;
        transform: translateY(-50%);
        bottom: -50%;
        left: 36px;
        ${text.xl};
        color: ${theme.color.dark};
        margin: 0;
      }
    }

    .steps {
      .step_item {
        margin-bottom: 49px;
        .step_item_detail {
          margin-bottom: 40px;
          .step_name {
            width: fit-content;
            p {
              padding: 0 8px;
              font-family: ${theme.fonts['Inter-Medium']};
              font-size: 16px;
              line-height: 24px;
              background: ${theme.color.linear_purple};
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              span {
                background: #1fd392;
                padding: 5px 10px;
                border-radius: 50%;
                margin-right: 9px;
                color: white;
              }
            }
            .underline {
              width: 100%;
              height: 4px;
              background: ${theme.color.linear_purple};
              margin-top: 8px;
            }
          }

          .des {
            margin-top: 20px;
            .note {
              font-family: ${theme.fonts['Inter-Regular']};
              font-size: 16px;
              line-height: 24px;
              color: ${theme.color.text_primary};
              padding-left: 8px;
              margin-bottom: 20px;
            }

            .note_detail {
              p {
                margin-top: 8px;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: ${theme.color.dark_gray};
              }
            }
          }
        }

        img {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .wrap_inside {
      .title {
        font-size: 32px;
      }
    }
  }

  @media screen and (max-width: 479px) {
    .wrap_inside {
      padding: 5px;
    }
  }
`;
export default WrapPayDetail;
