import { NewsHeader } from '@components/Layout/NewsLayout/Header';
import React, { useEffect } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import logo from '@assets/images/logo.svg';
import Image from '@components/Image';
import { useDispatch, useSelector } from 'react-redux';
import { selectStorePageStore } from '@containers/StorePage/store/selecters';
import { request } from '@api/axios';
import API_URL from '@api/url';
import { setBuyer } from '@containers/StorePage/store/actions';
import Button from '@components/Button';
import { ReactComponent as Cart } from '@assets/images/mobeos/home/cart.svg';
import { ReactComponent as User } from '@assets/images/mobeos/home/user.svg';
import { NAME_STORE_LOCAL } from '@containers/StorePage/store/constants';

interface LinkProps {
  name: string;
  path: string;
  exact: boolean;
  innerPage?: boolean;
}

const links = [
  {
    name: 'Trang chủ',
    path: '/',
    exact: true,
  },
  {
    name: 'Cửa hàng',
    path: '/store',
    exact: true,
  },
  // {
  //   name: 'Giới thiệu',
  //   path: '/#introduce',
  //   // exact: true,
  //   innerPage: true,
  // },
  {
    name: 'Hướng dẫn sử dụng',
    path: '/instructions',
    exact: true,
  },
  {
    name: 'Kênh người bán',
    path: '/login',
    exact: true,
  },
];

const loginRoutes = ['/login', '/signup', '/forgot-password', '/new-password'];

export default function Header() {
  const his = useHistory();
  const location = useLocation();
  const data = JSON.parse(localStorage.getItem(NAME_STORE_LOCAL) as string);

  const handleScroll = () => {
    let headerDOM = document.getElementById('header') as HTMLElement;
    let offsetY = window.pageYOffset;
    if (offsetY >= 40) {
      headerDOM.classList.add('scrolled');
    } else {
      headerDOM.classList.remove('scrolled');
    }
  };

  const handleCloseMenu = () => {
    let menuDOM = document.getElementsByClassName('links')[0] as HTMLElement;
    let overlayDOM = document.getElementById('overlay') as HTMLElement;

    menuDOM!.style.left = '-200px';
    overlayDOM!.style.display = 'none';
    document.body.style.height = 'auto';
    document.body.style.overflow = 'visible';
  };

  const handleOpenMenu = () => {
    let menuDOM = document.getElementsByClassName('links')[0] as HTMLElement;
    let overlayDOM = document.getElementById('overlay') as HTMLElement;

    menuDOM!.style.left = '0px';
    overlayDOM!.style.display = 'block';
    document.body.style.height = '100%';
    document.body.style.overflow = 'hidden';
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const renderLink = (
    { name, path, exact, innerPage }: LinkProps,
    index: number,
  ) => {
    if (innerPage)
      return (
        <a
          onClick={handleCloseMenu}
          className="link"
          href={`${path}`}
          // exact={exact}
          key={'link' + index}
        >
          {name}
        </a>
      );
    return (
      <NavLink
        onClick={handleCloseMenu}
        className="link"
        to={path}
        exact={exact}
        key={'link' + index}
      >
        {name}
      </NavLink>
    );
  };

  const onToggleMenu = () => {
    let menuDOM = document.getElementsByClassName('links')[0] as HTMLElement;
    if (menuDOM.style.left === '0px') {
      handleCloseMenu();
    } else {
      handleOpenMenu();
    }
  };

  const isLoginRoute = loginRoutes.find(route => {
    return location.pathname.includes(route);
  });

  const { buyer } = useSelector(selectStorePageStore);
  // console.log('buyer::', buyer);
  const dispatch = useDispatch();
  // get buyer profile
  useEffect(() => {
    const token = localStorage.getItem('buyer-token');
    if (token) {
      request({
        method: 'GET',
        url: API_URL.BUYER.PROFILE,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(res => {
        dispatch(setBuyer(res?.data || {}));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NewsHeader id="header" isLoginRoute={false}>
      <div className="header-wrapper">
        <div className="logo-group">
          <NavLink to="/" className="logo">
            <div className="logo-container">
              <Image src={logo} alt="" />
            </div>
          </NavLink>
        </div>
        <div className="links">
          <button id="close-menu" onClick={handleCloseMenu}>
            <FontAwesomeIcon icon={faWindowClose} />
          </button>
          <div className="link-group">
            {links.map((link: any, index: number) => {
              return renderLink(link, index);
            })}
          </div>
          <div className="buyer-group">
            <Button variant="ghost" onClick={() => his.push('/store/checkout')}>
              <div className="quantity">
                <Cart />
                {data?.[0]?.product?.length && (
                  <span>{data?.[0]?.product?.length}</span>
                )}
              </div>
              Giỏ hàng
            </Button>
            {buyer?.id ? (
              <div className="buyer-info">
                <div className="icon">
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 17.5383C16.5563 17.5383 15.3204 17.0243 14.2924 15.9963C13.2641 14.9681 12.75 13.7321 12.75 12.2883C12.75 10.8446 13.2641 9.60871 14.2924 8.58071C15.3204 7.55246 16.5563 7.03833 18 7.03833C19.4438 7.03833 20.6796 7.55246 21.7076 8.58071C22.7359 9.60871 23.25 10.8446 23.25 12.2883C23.25 13.7321 22.7359 14.9681 21.7076 15.9963C20.6796 17.0243 19.4438 17.5383 18 17.5383ZM6.75 28.9616V25.6267C6.75 24.8922 6.9495 24.212 7.3485 23.586C7.7475 22.96 8.28075 22.4787 8.94825 22.1422C10.4307 21.4155 11.9264 20.8703 13.4351 20.5068C14.9439 20.1433 16.4655 19.9616 18 19.9616C19.5345 19.9616 21.0561 20.1433 22.5649 20.5068C24.0736 20.8703 25.5693 21.4155 27.0518 22.1422C27.7193 22.4787 28.2525 22.96 28.6515 23.586C29.0505 24.212 29.25 24.8922 29.25 25.6267V28.9616H6.75ZM9 26.7116H27V25.6267C27 25.323 26.912 25.0417 26.736 24.783C26.56 24.5245 26.3211 24.3135 26.0194 24.15C24.7269 23.5135 23.4091 23.0312 22.0661 22.7032C20.7229 22.3755 19.3675 22.2116 18 22.2116C16.6325 22.2116 15.2771 22.3755 13.9339 22.7032C12.5909 23.0312 11.2731 23.5135 9.98062 24.15C9.67887 24.3135 9.44 24.5245 9.264 24.783C9.088 25.0417 9 25.323 9 25.6267V26.7116ZM18 15.2883C18.825 15.2883 19.5313 14.9946 20.1188 14.4071C20.7063 13.8196 21 13.1133 21 12.2883C21 11.4633 20.7063 10.7571 20.1188 10.1696C19.5313 9.58208 18.825 9.28833 18 9.28833C17.175 9.28833 16.4688 9.58208 15.8813 10.1696C15.2938 10.7571 15 11.4633 15 12.2883C15 13.1133 15.2938 13.8196 15.8813 14.4071C16.4688 14.9946 17.175 15.2883 18 15.2883Z"
                      fill="#484C8B"
                    />
                  </svg>
                </div>
                {buyer?.phone}

                <div className="menu-drop">
                  <div className="drop-header">
                    <User />
                    {buyer?.phone}
                  </div>
                  <div
                    className="drop-item"
                    onClick={e => {
                      e.preventDefault();
                      his.push('/store/order');
                    }}
                  >
                    <div className="drop-item_text">Quản lý đơn hàng</div>
                  </div>
                  <div
                    className="drop-item"
                    onClick={e => {
                      e.preventDefault();
                      localStorage.clear();
                      his.push('/');
                      window.location.reload();
                    }}
                  >
                    <div className="drop-item_text">Đăng xuất</div>
                  </div>
                </div>
              </div>
            ) : (
              <Button onClick={() => his.push('/store/auth')}>Đăng nhập</Button>
              // <div className="group-buttons">
              //   <NavLink
              //     onClick={handleCloseMenu}
              //     className="button_1"
              //     exact={true}
              //     to="/store/auth"
              //   >
              //     Đăng nhập
              //   </NavLink>
              // </div>
            )}
          </div>
        </div>
        <button id="toggle-menu" onClick={onToggleMenu}>
          <FontAwesomeIcon icon={faBars} />
        </button>
      </div>
    </NewsHeader>
  );
}
