/*
 *
 * AccountVerify style css file
 * make by phamthainb
 */

import theme from '@styles/theme';
import styled from 'styled-components';
import { Style as SDatePicker } from '@components/Input/DatePicker';
import { text } from '@styles/mixins';

type TInputText = {
  minWidth?: number;
};

export const InputText = styled.label.attrs(props => ({
  className: 'label',
}))<TInputText>`
  position: relative;
  margin-bottom: 24px;

  p.name {
    min-width: ${props => (props.minWidth ? props.minWidth + 'px' : '150px')};
    white-space: nowrap;
    font-size: 14px;
    line-height: 20px;
    color: ${theme.color.dark};
    /* height: 100%; */
    padding: 0;
    margin: 0;
    margin-bottom: 4px;
  }

  ${SDatePicker} {
    width: 100%;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    p.name {
      width: 100%;
      margin: 0;
      padding-bottom: 12px;
    }
  }
`;

const WrapAccountVerify = styled.div`
  .form-data {
    .btn-submit {
      margin-top: 40px;
      display: flex;
      justify-content: center;
    }
    .form-input {
      margin-bottom: 30px;
    }
    .upload {
      .text {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #5f6169;
        padding-bottom: 8px;
      }

      .upload-list-images {
        /* padding: 24px 12px 32px; */
        display: flex;
        flex-direction: row;
        gap: 32px;
        justify-content: center;
        align-items: start;

        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 320px;
          height: 180px;

          .title {
            font-size: 14px;
            line-height: 20px;
            color: ${theme.color.dark};
            text-align: left;
            width: 100%;
            margin-bottom: 4px;
          }

          .img {
            width: 100%;
            height: 100%;
            border: 1.6px dashed ${theme.color.text_primary};
            box-sizing: border-box;
            border-radius: 12px;
            position: relative;

            img {
              width: 68px;
              height: 68px;
              object-fit: contain;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              top: 12px;
            }
          }
          .input {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 24px;
            display: inline-block;
            border: 1px solid #de58fd;
            border-radius: 8px;
            color: ${theme.color.dark};
            padding: 10px 0;
            font-family: ${theme.fonts['Inter-Medium']};
            ${text.base}
            max-width: 143px;
            width: 100%;
            text-align: center;
            transition: all 0.3s;
            &:hover {
              background: ${theme.color.linear_purple};
              color: ${theme.color.white};
            }

            input {
              display: none;
            }
          }
        }
      }

      @media (max-width: 1199px) {
        .upload-list-images {
          padding: 20px 0;
          flex-wrap: wrap;
        }
      }
    }
  }
`;

export const WrapContentTab = styled.div`
  padding: 32px 44px 20px;

  .upload {
    .control_tab {
      margin-bottom: 16px;
      display: flex;
      .control_tab_item {
        background: #e3e8f0;
        border-radius: 8px 8px 0px 0px;
        padding: 8px 22px;
        margin-right: 8px;
      }
    }
  }

  @media (max-width: 1199px) {
    padding: 46px 46px 100px;
  }

  @media (max-width: 991px) {
    padding: 32px 44px;
  }
`;

export default WrapAccountVerify;
