/**
 *
 * TabsContent
 * make by phamthainb
 */
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as ReactTabs from 'react-tabs';
import { mixinsFlexCenter } from '@styles/mixins';
import useMediaQuery from '@hooks/useMediaQuery';
import useDetectClickOutSide from '@hooks/useDetectClickOutSide';
import theme from '@styles/theme';

const Tab = styled(ReactTabs.Tab)`
  /* border-radius: 12px 12px 0px 0px; */
  height: 56px;
  padding: 0 8px;
  ${mixinsFlexCenter};
  font-size: 16px;
  line-height: 24px;
  color: ${theme.color.cadet_blue};
  margin: 0 6px;
  transition: all 0.25s ease-in;
  position: relative;
  /* min-width: 198px; */
  cursor: pointer;

  &[aria-selected='true'] {
    color: #0086ff;
    /* -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */

    &::after {
      content: '';
      position: absolute;
      background: ${theme.color.linear_purple};
      height: 4px;
      width: 100%;
      bottom: 0;
    }
  }
`;

// control
const TabList = styled(ReactTabs.TabList)`
  position: relative;

  .control {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 0 20px;
    padding: 0;
    border-bottom: 1px solid #e6ebf1;
    gap: 20px;

    ${Tab} {
      outline: none;
      user-select: none;
    }
  }

  .show-item {
    border-radius: 12px 12px 0px 0px;
    height: 56px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    margin: 0 12px;
    transition: all 0.25s ease-in;
    min-width: 198px;
    cursor: pointer;
    background: #ffffff;
    color: #0086ff;
    border: 1px solid #e3e8f0;
    margin: 0;
    box-sizing: content-box;
    justify-content: start;

    position: relative;
  }

  @media (max-width: 767px) {
    .control {
      max-height: 0;
      padding: 0;
      margin: 0;
      overflow: hidden;
      transition: all 0.3s ease;
      width: 100%;
      flex-direction: column;
      padding: 0;
      margin: 0;
      left: 0;

      &.show {
        max-height: unset;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        z-index: 1;
        box-shadow: 0 3px 2px 0px rgb(206 189 189 / 30%);
        border: none;
        gap: 0;
      }

      ${Tab} {
        margin: 0;
        padding: 0;
        border-radius: 0;
        border: none !important;
        background-color: #ffffff;
        border-bottom: 1px solid #e6ebf1;
        /* color: #03cb83; */

        &[aria-selected='true'] {
          background-color: #ffffff;

          &::after {
            display: none;
          }
        }
      }
    }
  }
`;

// content
const TabPanel = styled(ReactTabs.TabPanel)`
  background: ${theme.color.rose_white};
  border-radius: 12px;
  transition: all 0.25s ease, padding 0s;

  /* &.react-tabs__tab-panel--selected {
    padding: 72px 52px;

  }

  @media (max-width: 1199px) {
    &.react-tabs__tab-panel--selected {
      padding: 48px;
    }
  }
  @media (max-width: 991px) {
    &.react-tabs__tab-panel--selected {
      padding: 32px;
    }
  }
  @media (max-width: 767px) {
    &.react-tabs__tab-panel--selected {
      padding: 16px;
    }
  } */
  @media (max-width: 767px) {
    border-radius: 0px 0px 12px 12px;
  }
`;

// wrapper
const Tabs = styled(ReactTabs.Tabs)``;

interface Props {
  control: string[];
  content: JSX.Element[];
  onChangeTab?: (index: number) => void;
  tabActive?: number;
}
/**
 *
 * @param control : string[];
 * @param content : JSX.Element[];
 * @param onChangeTab? : (index: number) => void;
 *
 */
const TabsComponent = ({ content, control, onChangeTab, tabActive }: Props) => {
  const { width } = useMediaQuery();
  const [tab, setTab] = useState(0);
  const [show, setShow] = useState(false);

  const ref = useRef<any>(null);
  const refParent = useRef<any>(null);

  useDetectClickOutSide({
    ref: ref,
    handler: () => {
      if (width < 768) {
        setShow(false);
      }
    },
    refParent: refParent,
    handlerParent: () => {},
  });

  // set default tab
  useEffect(() => {
    tabActive && setTab(tabActive);
    // console.log('tabActive', tabActive);
  }, [tabActive]);

  return (
    <Tabs
      onSelect={(index: number) => {
        if (index !== tab) {
          setTab(index);
          if (onChangeTab) {
            onChangeTab(index);
          }
        }
      }}
    >
      {/* tab control */}
      <TabList as="div">
        {width < 768 && (
          <span
            ref={refParent}
            className="show-item"
            onClick={() => setShow(!show)}
          >
            {control[tab]}
          </span>
        )}
        <ul ref={ref} className={`control ${show && 'show'}`}>
          {control.map((t, index) => (
            <Tab
              key={index}
              onClick={() => {
                setShow(false);
              }}
            >
              {t}
            </Tab>
          ))}
        </ul>
      </TabList>

      {/* tab content */}
      {content.map((t, index) => (
        <TabPanel key={index}>{t}</TabPanel>
      ))}
    </Tabs>
  );
};

export default {
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabsComponent,
};
