/*
 *
 * TransactionList
 * make by phamthainb
 */

import React, { memo } from 'react';
import ErrorBound from '@components/ErrorBound';
import useInjectReducer from '@redux/useInjectReducer';
import reducersTransactionList from './store/reducers';
import WrapTransactionList from './style';
import Section from '@containers/CLayout/LayoutDashBroad/Section';
import Search from './Search';
import TransactionContainer from './TransactionContainer';
import Handle from './Handle';

interface Props {}

// eslint-disable-next-line
function TransactionList({}: Props) {
  useInjectReducer('TransactionList', reducersTransactionList);
  return (
    <ErrorBound>
      <WrapTransactionList>
        <Section title="Quản lý giao dịch" handle={<Handle />}>
          <>
            <Search />
            <TransactionContainer />
          </>
        </Section>
      </WrapTransactionList>
    </ErrorBound>
  );
}
export default memo(TransactionList);
