import { mixinsFlexCenter, mixinsFlexColumnCenter, text, mixinsScrollBar } from '@styles/mixins';
/*
 *
 * Products style css file
 * make by phamthainb
 */

import Button from '@components/Button';
import theme from '@styles/theme';
import styled from 'styled-components';

export const SSearch = styled.div`
  padding: 0 10px;
  .field-input {
    margin-bottom: 15px;
    display: block;
    .text {
      margin: 4px 0;
      font-size: 16px;
      line-height: 19px;
      color: ${theme.color.text_phu};
    }
  }

  .btn-submit {
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding-bottom: 16px;

    ${Button} {
      width: 100%;
    }
  }
  @media (max-width: 767px) {
    padding: 0;
  }
`;

export const SHandle = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 16px;
  margin: 16px 0 12px;
  .print {
    border: 2px solid #de58fd;
    background: white !important;
    color: #de58fd;
    margin-right: 32px;
  }
  .add {
    margin-right: 16px;
    ${mixinsFlexCenter};
    font-weight: normal;
    svg,
    img {
      margin-right: 14px;
    }
  }
  .select {
    ${mixinsFlexCenter};
    label {
      color: ${theme.color.text_phu};
    }
  }
  @media (max-width: 767px) {
    padding: 0;
    .select {
      label {
        display: none;
      }
    }
    .print,
    .add {
      margin-right: 10px;
    }
  }
`;

export const SHandleModal = styled.form`
  display: flex;
  flex-direction: column;

  .category-input {
    width: 100%;
  }

  .col_right {
    .list-image {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: stretch;
      gap: 12px;
      margin: 8px 0;
      position: relative;

      .input-upload {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
      .img-upload {
        background: ${theme.color.rose_white};
        border-radius: 12px;
        border: 1.6px dashed ${theme.color.text_primary};
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 4px;
        height: 120px;
        width: 160px;

        img {
          object-fit: contain;
          max-width: 100%;
          max-height: 100%;
          cursor: pointer;
        }
        input {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          opacity: 0;
        }
      }
    }

    .image-skeleton,
    .image {
      width: 100%;
      ${mixinsFlexCenter};
    }
    .image-skeleton {
      height: 235px;
      background-color: #e3e8f0;
      border-radius: 4px;
    }
    .image {
      img {
        width: 100%;
        height: auto;
        border-radius: 4px;
      }
    }

    .modal_input {
      margin-bottom: 20px;
      label {
        color: ${theme.color.dark};
        display: inline-block;
        align-self: center;
        ${text.sm}
        margin-bottom: 4px;
      }
      .input_price {
        display: flex;

        .currency {
          width: 130px;
        }
      }
      textarea {
        font-family: ${theme.fonts['Inter-Regular']};
        border: 0.8px solid ${theme.color.cadet_blue};
        border-radius: 12px;
        padding: 12px 16px;
        ${mixinsScrollBar}
      }
    }
    .checkbox {
      &-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 0.8px solid ${theme.color.cadet_blue};
        border-radius: 12px;
        height: 48px;
        padding: 12px 16px;
        p {
          margin: unset;
        }
      }
    }
    .upload-image {
      ${mixinsFlexCenter};
      justify-content: flex-start;
      .upload-image_input {
        display: none;
      }
      .upload-image_label {
        display: inline-block;
        background-color: ${theme.color.link};
        border-radius: 4px;
        color: white;
        line-height: 24px;
        cursor: pointer;
        padding: 8px 32px;
        flex-wrap: nowrap;
        margin-bottom: 0;
        min-width: 145px;
      }
      .filename {
        display: inline-block;
        margin-left: 24px;
        color: ${theme.color.link};
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    .modal_buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      margin-top: 24px;
      /* margin-left: -16px; */
      ${Button} {
        display: inline-block;
        width: 160px;
        margin: unset;
      }
    }
    input[type='file'] {
      border: none;
    }
  }

  @media (max-width: 679px) {
    flex-direction: column;
    .col_left {
      margin-bottom: 20px;
      width: 100%;
      .image {
        margin: 0 auto;
        width: 193px;
        height: auto;
      }
    }
    .col_right {
      margin-left: 0;
    }
  }
`;

const WrapProduct = styled.div`
  table {
    tr {
      text-align: left;
    }
  }
  .content-section {
    padding: 0;
    box-shadow: unset;
    background: #f6f7fb;
    border-radius: 0;
    .content-container {
      .content-collapse {
        padding: 20px 44px;
        box-shadow: 0px 4px 25px rgba(141, 171, 255, 0.2);
        background: #ffffff;
        border-radius: 12px;
      }
      .content {
        margin-top: 20px;
        padding: 20px 44px;
        box-shadow: 0px 4px 25px rgba(141, 171, 255, 0.2);
        background: #ffffff;
        border-radius: 12px;
      }
    }
  }
  .pagination-container {
    padding: 16px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    label {
      color: #8e95a7;
    }
    .select {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  .product-detail {
    display: flex;
    .col_left {
      .image-skeleton {
        background-color: #e3e8f0;
      }
      .image-skeleton,
      .image {
        margin-top: 18px;
        position: relative;
        ${mixinsFlexColumnCenter};
        width: 270px;
        /* height: 336px; */
        height: auto;
        border-radius: 4px;
        .file-input {
          opacity: 0;
          transition: 0.3s;
          border-radius: 4px;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.3);
          ${mixinsFlexCenter};
          .button {
            position: relative;
            background-color: red;
            display: inline-block;
            color: white;
            line-height: 24px;
            padding: 8px 32px;
            border-radius: 4px;
            background-color: ${theme.color.link};
            input[type='file'] {
              opacity: 0;
              font-size: 0px;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              height: auto;
              cursor: pointer;
            }
          }
        }
        &:hover {
          .file-input {
            opacity: 1;
          }
        }
      }
      .image-skeleton {
        height: 336px;
      }
      .file-name {
        text-align: center;
        font-size: 16px;
        line-height: 24px;
        color: ${theme.color.link};
        margin-top: 12px;
        font-family: ${theme.fonts['K2D-Regular']};
      }

      .list-image {
        display: flex;
        max-width: 300px;
        overflow: auto;

        .image {
          width: 70px;
          height: 70px;
          margin-right: 10px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
    .col_right {
      margin-left: 60px;
      flex-grow: 1;
      .modal_input {
        margin-bottom: 20px;
        label {
          color: ${theme.color.text_phu};
          display: inline-block;
          align-self: center;
          line-height: 24px;
          margin-bottom: 4px;
        }
        .input_price {
          display: flex;
        }
      }
      .checkbox {
        display: flex;
        align-items: center;
        label {
          display: inline-block;
          margin-right: 34px;
          margin-bottom: 0;
        }
      }
      .modal_buttons {
        display: flex;
        margin-right: -16px;
        margin-top: 77px;
        ${Button} {
          display: inline-block;
          margin-right: 16px;
          border-radius: 4px;
          width: 160px;
          height: 40px;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .product-detail {
      .col_right {
        .modal_buttons {
          margin-top: 40px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .product-detail {
      flex-direction: column;
      .col_left {
        margin-bottom: 20px;
        .image {
          margin: 0 auto;
        }
        .image-skeleton {
          width: 100%;
        }
      }
      .col_right {
        margin-left: 0;
        .modal_buttons {
          justify-content: center;
        }
      }
    }
  }
`;

export const SDeleteModal = styled.div`
  .group-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
    .cancel {
      background: #fff;
      color: #de58fd;
      border: 1px solid #de58fd;
    }
  }
  ${mixinsFlexColumnCenter};
  p {
    color: ${theme.color.text_chinh};
    font-size: 24px;
    margin: 52px 0 44px;
    font-family: ${theme.fonts['K2D-Regular']};
    text-align: center;
  }
  .group-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    ${Button} {
      width: 160px;
      margin: unset;
    }
  }
  @media (max-width: 767px) {
    p {
      margin: 20px 0;
      font-size: 16px;
    }
  }
`;

export default WrapProduct;
