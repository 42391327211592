/*
 *
 * Order
 * make by phamthainb
 */

import ErrorBound from '@components/ErrorBound';
import { HandleError } from '@components/HandleResponse';
import Section from '@containers/CLayout/LayoutDashBroad/Section';
import useInjectReducer from '@redux/useInjectReducer';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from './DataTable';
import Search from './Search';
import { getDataOrder, setDataOrder } from './store/actions';
import reducersOrder from './store/reducers';
import { selectOrderStore } from './store/selecters';
import WrapOrder from './style';

interface Props {}

// eslint-disable-next-line
function Order({}: Props) {
  useInjectReducer('Order', reducersOrder);

  const { search, pagination, reloadData } = useSelector(selectOrderStore);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);

    getDataOrder({ search, pagination })
      .then(res => {
        dispatch(
          setDataOrder({ data: res.data.data, total: res.data.meta.total }),
        );
      })
      .catch(HandleError)
      .finally(() => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, pagination, reloadData]);

  return (
    <ErrorBound>
      <WrapOrder>
        <Section title="Quản lý Đơn hàng">
          <>
            <Search />
            <DataTable loading={loading} />
          </>
        </Section>
      </WrapOrder>
    </ErrorBound>
  );
}
export default memo(Order);
