/**
 *
 * Table
 * make by phamthainb
 */
import React from 'react';
import styled from 'styled-components';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ReactComponent as Empty } from '@assets/images/mobeos/dashboard/empty.svg';
import VALIDATE from '@helpers/validate';
import { mixinsScrollBarHeight } from '@styles/mixins';
import theme from '@styles/theme';
import Skeleton from 'react-loading-skeleton';

type PropsStyle = {
  border?: boolean;
  minWidth?: number;
  [key: string]: any;
};

type Props = {
  style?: PropsStyle;
  isLoading?: boolean;
  thead: any;
  tbody: any;
};

const b = `1px solid ${theme.color.line}`;

const Table = ({ style, isLoading, thead, tbody }: Props) => {
  return (
    <STable {...style}>
      <table>
        <thead>{thead}</thead>
        <tbody>
          {isLoading ? (
            Array(10)
              .fill(null)
              .map((_, index) => (
                <tr key={`table-skeleton-${index}`}>
                  <td colSpan={100}>
                    <Skeleton height={52} duration={0.8} />
                  </td>
                </tr>
              ))
          ) : tbody.length > 0 ? (
            tbody
          ) : (
            <tr>
              <td colSpan={100}>
                <div className="empty">
                  <Empty />
                  {VALIDATE.NOTHING_HERE}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </STable>
  );
};

export const STable = styled.div<PropsStyle>`
  margin-top: 20px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 16px;
  color: ${theme.color.text_phu};
  overflow: auto;
  ${mixinsScrollBarHeight};

  table {
    border-spacing: 0 8px;
    width: 100%;
    border-collapse: separate;
    text-align: center;
    min-width: ${props => (props.minWidth ? props.minWidth + 'px' : '500px')};
    gap: 8px;
    thead {
      font-size: 16px;
      line-height: 24px;
      color: ${theme.color.dark_gray};
      margin-bottom: 0px;
      position: relative;
      text-align: left;
      white-space: nowrap;
      th {
        font-family: ${theme.fonts['Inter-Regular']};
        padding: 16px 20px;
        font-weight: 400;
        border-bottom: 1px solid #e6ebf1;
      }
    }
    tbody {
      position: relative;
      tr {
        height: 52px;
        padding: 0;
        text-align: left;
        td {
          .run {
            width: 130px;
            height: 36px;
            border-radius: 8px;
            background: #6454c021;
            color: #00bc6c;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .stop {
            width: 130px;
            height: 36px;
            border-radius: 8px;
            background: #6454c021;
            color: #2e315b;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .unused,
          .active,
          .success {
            width: 130px;
            height: 36px;
            border-radius: 8px;
            background: #6454c021;
            color: #6554c0;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .used,
          .lock,
          .processing {
            width: 130px;
            height: 36px;
            border-radius: 8px;
            background: #0073df11;
            color: #0075df;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .disable,
          .fail {
            width: 130px;
            height: 36px;
            border-radius: 8px;
            background: #b9220011;
            color: #b92200;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .expired,
          .reject {
            width: 130px;
            height: 36px;
            border-radius: 8px;
            background: #b9220011;
            color: #8e95a7;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        &:hover {
          background: #0073df11;
          td {
            &:first-child {
              color: #0075df;
            }
          }
        }
        /* &:first-child {
          td {
            border-top: 1px solid #e6ebf1;
          }
        } */
        /* &:last-child {
          td {
            border-bottom: 1px solid #e6ebf1;
          }
        } */
        td {
          padding: 16px 20px;
          max-width: 250px;
        }
      }
      .empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        color: ${theme.color.dark_gray};
      }
    }
  }
`;

export default Table;
