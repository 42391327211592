/*
 *
 * Products
 * make by phamthainb
 */

import React, { memo, useEffect, useState } from 'react';
import ErrorBound from '@components/ErrorBound';
import useInjectReducer from '@redux/useInjectReducer';
import reducersProducts from './store/reducers';
import WrapProducts from './style';
import Section from '@containers/CLayout/LayoutDashBroad/Section';
import Search from './Search';
import Handle from './Handle';
import DataTable from './DataTable';
import { selectProductsStore } from './store/selecters';
import { useDispatch, useSelector } from 'react-redux';
import { getData, setData } from './store/actions';
import { HandleError } from '@components/HandleResponse';
import ShopInfo from './ShopInfo';

interface Props {}

// eslint-disable-next-line
function Products({}: Props) {
  useInjectReducer('Products', reducersProducts);
  const { pagination, onRefresh, search } = useSelector(selectProductsStore);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    // console.log('search::', search);

    getData({ pagination, search })
      .then(res => {
        dispatch(setData({ data: res.data, total: res.data.meta.total }));
      })
      .catch(HandleError)
      .finally(() => setLoading(false));
  }, [pagination, onRefresh, search, dispatch]);

  return (
    <ErrorBound>
      <WrapProducts>
        <Section title="Gian hàng" handle={<Handle />}>
          <div className="content-container">
            <div className="content-collapse">
              <ShopInfo />
            </div>
            <div className="content">
              <h2 className="ds-title">Danh sách sản phẩm</h2>
              <Search />
              <DataTable loading={loading} />
            </div>
          </div>
        </Section>
      </WrapProducts>
    </ErrorBound>
  );
}
export default memo(Products);
