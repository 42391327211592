import theme from '@styles/theme';
import styled from 'styled-components';
import Input from '@components/Input';
import { mixinsFlexCenter, text } from '@styles/mixins';

export const Header = styled.header`
  /* margin-bottom: 50px; */
  width: 100%;
  min-height: 170px;
  padding: 36px 58px 16px;
  background: ${theme.color.dark};
  box-sizing: border-box;

  .header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-bottom {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav-wrapper {
    .close {
      display: none;
    }
  }

  .nav {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    height: 100%;
    .nav-item {
      position: relative;
      text-decoration: none;
      color: ${theme.color.cadet_blue};
      ${text.base};
      padding: 0 8px;
      cursor: pointer;
      &:hover,
      &.active {
        color: ${theme.color.rose_white};
        &::after {
          width: 100%;
        }
      }
      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 4px;
        background: ${theme.color.linear_purple};
        margin-top: 12px;
        left: 0;
        right: 0;
        transition: 0.5s;
      }
    }

    .dropdown-item {
      position: relative;

      &:hover {
        .wrapper {
          display: block;
        }
        .nav-item {
          color: ${theme.color.rose_white};
          &::after {
            width: 100%;
          }
        }
      }
      .wrapper {
        position: absolute;
        top: 20px;
        right: 0;
        display: none;
        z-index: 2;
        width: 100%;
        .child-item {
          ${text.base}
          border-radius: 12px;
          padding: 16px 12px;
          background: ${theme.color.mirage};
          margin-top: 20px;
          &__item {
            color: ${theme.color.cadet_blue};
            text-decoration: none;
            display: block;
            &:not(:last-child) {
              margin-bottom: 20px;
            }
            &:hover {
              color: ${theme.color.rose_white};
            }
          }
        }
      }
    }
  }

  .greeting {
    .hello {
      ${text.lg}
      font-family: ${theme.fonts['Inter-Regular']};
      color: ${theme.color.rose_white};
      margin: 0;
      span {
        font-style: italic;
      }
    }
    p {
      color: #97ceff;
      ${text.sm}
      margin: 2px 0 0;
    }
  }

  .logo {
    ${mixinsFlexCenter};
    position: relative;
    z-index: 2;
    cursor: pointer;
    img {
      height: 40px;
    }

    button {
      margin-right: 24px;
    }
    @media (max-width: 479px) {
      svg.icon-logo {
        width: 100px;
      }
    }
  }

  .info-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 -15px;

    a {
      text-decoration: none !important;
      color: #5f6169;
    }

    .info-user__child:nth-child(n) {
      margin: 0 15px;
    }

    @media (max-width: 991px) {
      .info-user__child:nth-child(n) {
        margin: 0;
      }
    }

    .search {
      position: relative;

      .icon-search {
        position: absolute;
        top: 50%;
        left: 3px;
        cursor: pointer;
        transform: translateY(-50%);
      }

      ${Input} {
        padding-left: 35px;
      }
    }

    .notify {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      box-shadow: '0px 4px 12px rgba(118, 120, 133, 0.15)';
      background: ${theme.color.mirage};
      position: relative;
      ${mixinsFlexCenter};
      cursor: pointer;

      img.icon {
      }

      .number-count {
        border-radius: 50%;
        position: absolute;
        min-width: 14px;
        height: 14px;
        right: 7px;
        top: 7px;
        background: ${theme.color.red};
        color: ${theme.color.white};
        ${mixinsFlexCenter};

        font-weight: 300;
        font-size: 10px;
        line-height: 13px;
        padding: 0 3px;
      }
    }

    .user {
      ${mixinsFlexCenter};
      margin: 0 -5px;
      transition: all 0.45s ease;
      height: 48px;
      padding: 8px;
      border-radius: 32px;
      background: ${theme.color.mirage};

      /* & > * {
        margin: 0 5px;
      } */

      &-name {
        color: ${theme.color.rose_white};
        margin-right: 16px;
      }

      &-avatar {
        ${mixinsFlexCenter};
        border-radius: 50%;
        height: 32px;
        width: 32px;
        position: relative;
        display: block;
        overflow: hidden;
        margin-right: 12px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-handle {
        cursor: pointer;
        width: 24px;
        height: 24px;
      }

      &:hover {
        /* background-color: ${theme.color.gray}; */
        cursor: pointer;
      }
    }
  }

  /* @media (min-width: 1440px) {
    padding: 36px 24px;
    z-index: 1;
    position: relative;
  } */
  @media (max-width: 1440px) {
    padding: 24px 32px;
  }

  @media (max-width: 1280px) {
    .header-bottom {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
    .nav {
      .nav-item {
        ${text.sm}
      }
      .dropdown-item {
        .wrapper {
          .child-item {
            ${text.sm}
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .nav {
      gap: 10px;
    }
    .info-user {
      gap: 20px;
    }
    .list-options.show {
      width: 200px !important;
    }
    .header-bottom {
      .nav-wrapper {
        position: fixed;
        top: 0;
        left: -400px;
        bottom: 0;
        z-index: 9999;
        overflow: auto;
        max-width: 400px;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(5px);
        display: grid;
        place-items: center;
        transition: all 0.25s ease;
        .close {
          display: block;
          position: absolute;
          top: 20px;
          right: 20px;
        }
        &.show_sidebar {
          left: 0px;
        }
      }
      .nav {
        /* flex-direction: column;
        align-items: center;
        justify-content: center; */
        display: initial;
        box-sizing: border-box;
        padding: 30px 0;
        height: auto;
        .nav-item {
          color: ${theme.color.rose_white};
          margin: 30px 0;
          display: block;
        }
      }
    }
  }

  @media (max-width: 767px) {
    padding: 32px 14px;

    .info-user {
      margin: 0;
    }
  }
`;
