import { TabsContent as Tabs } from '@components/index';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setType } from './store/actions';
import { TType } from './store/types';
import { STabs } from './style';
import TransactionTab from './TransactionTab';
import WithDrawTab from './WithDrawTab';

const types = [TType.plus, TType.withdraw];
const content = [
  <WithDrawTab type={types[1]} />,
  <TransactionTab type={types[0]} />,
];
const control = ['Rút tiền', 'Cộng tiền'];

export default function TransactionContainer() {
  const dispatch = useDispatch();

  return (
    <STabs>
      {/* <Handle /> */}
      <Tabs.TabsComponent
        onChangeTab={index => {
          dispatch(setType(types[1 - index]));
        }}
        content={content}
        control={control}
      />
    </STabs>
  );
}
