/*
 *
 * PaymentLinks :  Giao dịch
 * make by phamthainb
 *
 */

import ErrorBound from '@components/ErrorBound';
import { HandleError } from '@components/HandleResponse';
import Section from '@containers/CLayout/LayoutDashBroad/Section';
import useInjectReducer from '@redux/useInjectReducer';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from './DataTable';
import Handle from './Handle';
import Search from './Search';
import { getData, setData } from './store/actions';
import reducersPaymentLinks from './store/reducers';
import { selectPaymentLinksStore } from './store/selecters';
import WrapPaymentLinks from './style';

interface Props {}

// eslint-disable-next-line
function PaymentLinks({}: Props) {
  useInjectReducer('PaymentLinks', reducersPaymentLinks);

  const { search, pagination, reloadData } = useSelector(
    selectPaymentLinksStore,
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    getData({ search, pagination })
      .then(res => {
        dispatch(setData({ data: res.data.data, total: res.data.meta.total }));
      })
      .catch(HandleError)
      .finally(() => setLoading(false));
  }, [search, pagination, dispatch, reloadData]);

  return (
    <ErrorBound>
      <WrapPaymentLinks>
        <Section title="Quản lý danh sách giao dịch" handle={<Handle />}>
          <>
            <Search />
            <DataTable loading={loading} />
          </>
        </Section>
      </WrapPaymentLinks>
    </ErrorBound>
  );
}
export default memo(PaymentLinks);
