import { text } from '@styles/mixins';
import theme from '@styles/theme';
import React from 'react';
import styled from 'styled-components';

const padToTwo = (number: number) => {
  if (number < 10) return '0' + number;
  return number;
};

export default function CountDownTimer({ time }: { time: number }) {
  return (
    <SCountDown>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.0381 19.5C9.94425 19.5 8.17084 18.7736 6.71784 17.3207C5.26467 15.8679 4.53809 14.0948 4.53809 12.0015C4.53809 9.90817 5.26467 8.13458 6.71784 6.68075C8.17084 5.22692 9.94425 4.5 12.0381 4.5C13.2073 4.5 14.3137 4.75992 15.3573 5.27975C16.4008 5.79975 17.2688 6.53342 17.9611 7.48075V4.5H19.4611V10.6152H13.3458V9.1155H17.2958C16.7688 8.15 16.038 7.38942 15.1033 6.83375C14.1688 6.27792 13.1471 6 12.0381 6C10.3714 6 8.95475 6.58333 7.78809 7.75C6.62142 8.91667 6.03809 10.3333 6.03809 12C6.03809 13.6667 6.62142 15.0833 7.78809 16.25C8.95475 17.4167 10.3714 18 12.0381 18C13.3214 18 14.4798 17.6333 15.5131 16.9C16.5464 16.1667 17.2714 15.2 17.6881 14H19.2688C18.815 15.632 17.9211 16.9567 16.5871 17.974C15.2531 18.9913 13.7368 19.5 12.0381 19.5Z"
          fill="url(#paint0_linear_547_18034)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_547_18034"
            x1="4.53809"
            y1="12"
            x2="19.4611"
            y2="12"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#DE58FD" />
            <stop offset="1" stop-color="#0086FF" />
          </linearGradient>
        </defs>
      </svg>
      <div>
        <span>{padToTwo(Math.floor(time / 60))}</span> :{' '}
        <span>
          {padToTwo(Math.floor((time - Math.floor(time / 60) * 60) % 60))}
        </span>
      </div>
    </SCountDown>
  );
}

const SCountDown = styled.div`
  background: ${theme.color.linear_purple};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${text.lg};
  font-family: ${theme.fonts['Inter-Medium']};
  transition: all 0.3s ease;
  word-spacing: 1px;
  display: flex;
  align-items: center;
  gap: 4px;
`;
