/*
 *
 * AccountVerify
 * make by phamthainb
 */

import React, { memo, useState } from 'react';
import ErrorBound from '@components/ErrorBound';
import useInjectReducer from '@redux/useInjectReducer';
import reducersAccountVerify from './store/reducers';
import WrapAccountVerify from './style';
import { TabsContent as Tabs } from '@components/index';
import Contact from './Contact';
import Business from './Business';
// import Bank from '@containers/InfoUser/Bank';

interface Props { }

const control = [
  'Thông tin liên hệ',
  'Thông tin doanh nghiệp',
  // 'Tài khoản ngân hàng'
];

// eslint-disable-next-line
function AccountVerify({ }: Props) {
  useInjectReducer('AccountVerify', reducersAccountVerify);

  const [tab, setTab] = useState(0);
  const content = [
    Contact({ callAPI: tab }),
    Business({ callAPI: tab }),
    // Bank()
  ];

  return (
    <ErrorBound>
      <WrapAccountVerify>
        <Tabs.TabsComponent
          control={control}
          content={content}
          onChangeTab={tab => setTab(tab)}
        />
      </WrapAccountVerify>
    </ErrorBound>
  );
}
export default memo(AccountVerify);
