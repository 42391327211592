import React, { CSSProperties } from 'react';
import S, { Props, components } from 'react-select';
import { stylesCustom } from './SelectBase';

type SelectProps = Props & {
  styles?: CSSProperties;
}

export default function Select(props: SelectProps) {
  return (
    <S
      {...props}
      styles={stylesCustom({
        ...props.styles,
      })}
      components={{ DropdownIndicator }}
    />
  );
}

export function SelectCustom(props: Props) {
  return <S {...props} />;
}

export const DropdownIndicator: React.FC<any> = props => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5 15.0384L6.84619 9.38464L7.90002 8.33081L12.5 12.9308L17.1 8.33081L18.1538 9.38464L12.5 15.0384Z"
          fill="#0B0C17"
        />
      </svg>
    </components.DropdownIndicator>
  );
};
