import { requestInterToken } from '@api/axios';
import API_URL from '@api/url';
import Button from '@components/Button';
import { HandleError } from '@components/HandleResponse';
import exportExcel from '@helpers/exportExcel';
import { clearParams, convertStatus } from '@helpers/format';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { selectPaymentLinksStore } from './store/selecters';
import { SHandle } from './style';

export const mapDataExcel = (data: any[]) => {
  return data.map((item, index) => ({
    STT: index + 1,
    'Mã đơn hàng': item?.codeBill,
    'Kênh thanh toán': item?.transactionsGateway,
    'Số tiền': item?.money,
    'Ngày tạo': item?.createdAt,
    'Trạng thái thanh toán':
      item.paid === null
        ? 'Đang chờ xử lý'
        : item.paid === 0
        ? 'Thất bại'
        : 'Thành công',
    'Trạng thái Đơn hàng': convertStatus(item?.statusDetail),
    // 'Yêu cầu xử lý': item?.codeBill,
    'Đơn hàng': item?.url,
  }));
};

export default function Handle() {
  const history = useHistory();
  const { search } = useSelector(selectPaymentLinksStore);

  const action = async () => {
    await requestInterToken({
      method: 'GET',
      url: API_URL.PAYMENTLINK.GET,
      params: clearParams({ ...search, listType: 'excel' }),
    })
      .then(res => {
        // FileSaver.saveAs(res.data, 'payment-links.xlsx');
        exportExcel(mapDataExcel(res.data), 'Danh sách giao dịch');
      })
      .catch(HandleError);
  };

  return (
    <SHandle>
      <Button
        onClick={() => {
          history.push('/dashboard/create-payment-links');
        }}
        className="add-new"
      >
        Tạo mới
      </Button>

      <Button
        className="print"
        style={{
          background: '#fff',
          border: '2px solid #DE58FD',
          color: '#000',
        }}
        onClick={() => action()}
      >
        Export
      </Button>
    </SHandle>
  );
}
