import React, { useEffect, useState } from 'react';
import { Col, Row } from 'styled-bootstrap-grid';
import ICredit from '@assets/images/mobeos/dashboard/empty.svg';
import { SWithdr, StyleModal1 } from './style';
import Modal from '@components/Modal';
import { useDispatch } from 'react-redux';
import ModalOTP from './ModalOTP';
import { getBanks, setBanks } from './store/actions';
import Withdra from '@assets/images/withdra.svg';
import { onRefresh } from '@containers/App/store/actions';
import WithdrLocal from './WithdrLocal';
import WithdrGlobal from './WithdrGlobal';

export enum TypeWallet {
  local = 'local',
  global = 'global',
}

export const default_state = {
  local: {
    withdraw: 0,
    fee: 0,
    total: 0,
    program: '',
    open: false,
  },
  global: {
    withdraw: 0,
    fee: 0,
    total: 0,
    program: '',
    open: false,
  },
};

// type value => api caculate-fee => api/withdraw => /merchant/create-otp => merchant/check-otp
export default function Withdr() {
  const dispatch = useDispatch();

  const [isShowing1, toggleModal1] = useState(false); // otp
  const [isShowing, toggleModal] = useState(false); // notify
  const [triggerReset, setTriggerReset] = useState(false); // trigger reset data form

  const onTriggerReset = () => {
    setTriggerReset(!triggerReset);
  };

  useEffect(() => {
    getBanks().then(res =>
      dispatch(setBanks(res.data.data, res.data.meta.total)),
    );
    // .catch(HandleError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDone = () => {
    dispatch(onRefresh()); // refesh money in wallet
    toggleModal(false); // notify
    toggleModal1(false); // otp
    onTriggerReset();
  };

  const onCancel = () => {
    dispatch(onRefresh()); // refesh money in wallet
    toggleModal1(false); // otp
    onTriggerReset();
  };

  return (
    <SWithdr>
      <Row>
        <Col xl={6} lg={12} md={12} sm={12}>
          <WithdrLocal
            toggleModal1={() => {
              toggleModal1(!isShowing1);
            }}
            triggerReset={triggerReset}
          />

          <WithdrGlobal
            toggleModal1={() => {
              toggleModal1(!isShowing1);
            }}
            triggerReset={triggerReset}
          />
        </Col>
        <Col xl={6} lg={12} md={12} sm={12} className="center">
          <img src={ICredit} alt="credit" />
        </Col>
      </Row>

      {isShowing1 && (
        <Modal
          title="Xác nhận mã OTP"
          isShowing={isShowing1}
          toggleModal={onCancel}
          overwriteChild={{ style: { maxWidth: '720px' } }}
        >
          <ModalOTP
            toggleModalNotify={() => {
              toggleModal(!isShowing);
            }}
            toggleModal={toggleModal1}
          />
        </Modal>
      )}

      <Modal
        title=""
        isShowing={isShowing}
        toggleModal={onDone}
        overwriteChild={{ style: { maxWidth: '620px' } }}
      >
        <StyleModal1>
          <img src={Withdra} alt="withdra" />
          <p>
            Bạn đã tạo lệnh rút tiền thành công. Hệ thống đang trong quá trình
            xử lý.
          </p>
        </StyleModal1>
      </Modal>
    </SWithdr>
  );
}
