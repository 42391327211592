import React from 'react';
import { GlobalStyle } from '@styles/global-styles';
import { ThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// css style configs
import theme from '@styles/theme';
import '@styles/fonts.css'; // import config font define
import 'sweetalert2/dist/sweetalert2.min.css';
import { BaseCSS } from 'styled-bootstrap-grid';
// diff import
import Loading from '@components/Loading';
import { selectAppStore } from './store/selecters';
// import LanguageProvider from './LanguageProvider';
import NotFoundPage from '@components/NotFoundPage';
import { LayoutDashBroad, LayoutNews } from '@containers/CLayout';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// import Demo from '@containers/CLayout/Demo';

// load container
import loadable from '@components/LazyLoad';
import PaymentChecking from '@containers/PaymentClient/PaymentChecking';
import PayDetail from '@containers/Instructions/PayDetail';
const Home = loadable(() => import('@containers/Home'));
const PaymentVNPay = loadable(
  () => import('@containers/PaymentClient/PaymentVNPay'),
);
const Dashboard = loadable(() => import('@containers/Dashboard'));
const Login = loadable(() => import('@containers/Login'));
const AccountVerify = loadable(() => import('@containers/AccountVerify'));
const PaymentLinks = loadable(() => import('@containers/PaymentLinks'));
const Refund = loadable(() => import('@containers/Refund'));
const TransactionList = loadable(() => import('@containers/TransactionList'));
const Withdrawal = loadable(() => import('@containers/Withdrawal'));
//const Customer = loadable(() => import('@containers/Customer'));
const Order = loadable(() => import('@containers/Order'));
const InfoUser = loadable(() => import('@containers/InfoUser'));
const ForgotPassword = loadable(() => import('@containers/ForgotPassword'));
const NewPassword = loadable(() => import('@containers/NewPassword'));
const ChangePassword = loadable(() => import('@containers/ChangePassword'));
const SettingLink = loadable(() => import('@containers/SettingLink'));
const Shop = loadable(() => import('@containers/Shop'));
const Signup = loadable(() => import('@containers/Signup'));
const Overlay = loadable(() => import('@components/Overlay'));
const CreatePaymentLink = loadable(
  () => import('@containers/CreatePaymentLink'),
);
const Products = loadable(() => import('@containers/Products'));
const VerifyPassword = loadable(
  () => import('@containers/NewPassword/VerifyPassword'),
);
const ProductDetail = loadable(
  () => import('@containers/Products/ProductDetail'),
);
const Notification = loadable(() => import('@containers/Notification'));
const ProtectRoute = loadable(() => import('@components/ProtectRoute'));
const PaymentClient = loadable(() => import('@containers/PaymentClient'));
const AlertOffline = loadable(() => import('./AlertOffline'));
const PaymentNotify = loadable(
  () => import('@containers/PaymentClient/PaymentNotify'),
);
const Instructions = loadable(() => import('@containers/Instructions'));
const SignupDetail = loadable(
  () => import('@containers/Instructions/SignupDetail'),
);
const LoginDetail = loadable(
  () => import('@containers/Instructions/LoginDetail'),
);
const AuthenticationDetail = loadable(
  () => import('@containers/Instructions/AuthenticationDetail'),
);
//const InstructionDetail = loadable(() => import('@containers/Instructions/Details'));
const StorePage = loadable(() => import('@containers/StorePage'));
const PaymentQr = loadable(() => import('@containers/PaymentQr'));

require('moment/locale/vi.js');

function App() {
  const { loading, ui } = useSelector(selectAppStore);
  // console.log('process.env.REACT_APP_CAPCHA', process.env.REACT_APP_CAPCHA);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          {/* For demo styles component and simple code  demo */}
          {/* <Route path="/demo" component={Demo} /> */}

          {/* Dashbroad layout */}
          <Route path="/dashboard/*" exact>
            <LayoutDashBroad>
              <Switch>
                <ProtectRoute
                  exact
                  path={['/dashboard/index', '/dashboard']}
                  children={<Dashboard />}
                />
                <ProtectRoute
                  path="/dashboard/account-verify"
                  children={<AccountVerify />}
                />
                <ProtectRoute
                  path="/dashboard/payment-links"
                  children={<PaymentLinks />}
                />
                <ProtectRoute
                  path="/dashboard/create-payment-links"
                  children={<CreatePaymentLink />}
                />
                <ProtectRoute path="/dashboard/refund" children={<Refund />} />
                <ProtectRoute
                  path="/dashboard/transaction-list"
                  children={<TransactionList />}
                />
                <ProtectRoute
                  path="/dashboard/withdrawal"
                  children={<Withdrawal />}
                />
                <ProtectRoute
                  path="/dashboard/notification"
                  children={<Notification />}
                />
                <ProtectRoute
                  path="/dashboard/info-user"
                  children={<InfoUser />}
                />
                <ProtectRoute
                  path="/dashboard/products"
                  children={<Products />}
                />
                <ProtectRoute
                  path="/dashboard/product/:id"
                  children={<ProductDetail />}
                />
                {/* <ProtectRoute
                  path="/dashboard/customer"
                  children={<Customer />}
                /> */}
                <ProtectRoute path="/dashboard/order" children={<Order />} />
                <Route path="*">
                  <NotFoundPage />
                </Route>
              </Switch>
            </LayoutDashBroad>
          </Route>

          {/* Client Payment */}
          <Route path="/payment/:link">
            <PaymentClient />
          </Route>
          <Route path="/payment-vnpay/:link">
            <PaymentVNPay />
          </Route>
          <Route path="/payment-notification">
            <PaymentNotify />
          </Route>
          <Route path="/payment-qr/:link">
            <PaymentQr />
          </Route>

          {/* check visa master card when mapping callback */}
          <Route path="/payment-visa-checking">
            <PaymentChecking />
          </Route>

          {/* News layout */}
          <Route>
            <LayoutNews>
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route path="/login">
                  <Login />
                </Route>
                <Route path="/forgot-password">
                  <ForgotPassword />
                </Route>
                <Route exact path="/new-password">
                  <NewPassword />
                </Route>
                <Route exact path="/change-password">
                  <ChangePassword />
                </Route>
                <Route exact path="/setting-link">
                  <SettingLink />
                </Route>
                <Route exact path="/shop/:url">
                  <Shop />
                </Route>
                <Route path="/new-password/:resetToken">
                  <VerifyPassword />
                </Route>
                <Route path="/signup">
                  <Signup />
                </Route>
                <Route path="/instructions" exact>
                  <Instructions />
                </Route>
                <Route path="/instructions/signup-detail">
                  <SignupDetail />
                </Route>
                <Route path="/instructions/login-detail">
                  <LoginDetail />
                </Route>
                <Route path="/instructions/authentication-detail">
                  <AuthenticationDetail />
                </Route>
                <Route path="/instructions/pay">
                  <PayDetail />
                </Route>
                <Route path="/store">
                  <StorePage />
                </Route>
                <Route path="*">
                  <NotFoundPage />
                </Route>
              </Switch>
            </LayoutNews>
          </Route>
        </Switch>
      </Router>

      {/* // extra config global */}
      <Loading active={loading} />
      <GlobalStyle />
      <BaseCSS />
      <Overlay show={Boolean(ui.overlay)} />
      <AlertOffline />
    </ThemeProvider>
  );
}

export default App;
