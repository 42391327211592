/**
 *
 * config for react-select
 *
 */

import theme from '@styles/theme';
import { CSSProperties } from 'react';

const heightStylesDefault = { height: '48px', minHeight: '48px' };

const stylesCustom = (heightStylesProps?: CSSProperties) => {
  const heightStyles = heightStylesProps || heightStylesDefault;

  return {
    control: (provided: any) => ({
      ...provided,
      color: '#043468',
      ...heightStyles,
      borderRadius: 12,
      borderColor: '#A5ADC2',
    }),

    valueContainer: (provided: any) => ({
      ...provided,
      ...heightStyles,
      padding: '12px 16px',
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: theme.color.dark,
      minWidth: '100%',
      height: '80%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: 'calc(100% - 8px)',
      transform: 'translateY(-30%)',
    }),
    input: (provided: any) => ({
      ...provided,
      margin: '0px',
    }),

    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: 'none',
    }),

    indicatorsContainer: (provided: any) => ({
      ...provided,
      ...heightStyles,
    }),

    dropdownIndicator: (provided: any) => ({
      ...provided,
      padding: '0 16px',
    }),

    singleValue: (provided: any) => ({
      ...provided,
      color: theme.color.dark,
      minWidth: '100%',
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 20,
      borderRadius: 12,
      boxShadow: '0px 0px 12px 2px #F4D0FD1F',
      border: '1px solid #E6EBF1',
      padding: '4px 0',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      overflow: 'hidden',
      color: theme.color.dark,
      backgroundColor:
        state.isFocused || state.isSelected ? '#F6F7FB' : '#FFFEFE',
      padding: '12px 16px',
      cursor: state.isFocused && 'pointer',
    }),
    container: (provided: any) => ({
      ...provided,
      width: '100%',
    }),
  };
};

export { stylesCustom };
