/*
 *
 * Refund
 * make by phamthainb
 */

import React, { memo, useEffect, useState } from 'react';
import ErrorBound from '@components/ErrorBound';
import useInjectReducer from '@redux/useInjectReducer';
import reducersRefund from './store/reducers';
import WrapRefund from './style';
import { useDispatch, useSelector } from 'react-redux';
import { selectRefundStore } from './store/selecters';
import { HandleError } from '@components/HandleResponse';
import { getData, setData } from './store/actions';
import Search from './Search';
import Section from '@containers/CLayout/LayoutDashBroad/Section';
import Handles from './Handles';
import DataTable from './DataTable';

interface Props {}

// eslint-disable-next-line
function Refund({}: Props) {
  useInjectReducer('Refund', reducersRefund);

  const { pagination, search } = useSelector(selectRefundStore);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    getData({ pagination, search })
      .then(res => {
        dispatch(setData({ data: res.data, total: res.data.meta.total }));
      })
      .catch(HandleError)
      .finally(() => setLoading(false));
  }, [pagination, search, dispatch]);

  return (
    <ErrorBound>
      <WrapRefund>
        <Section title="Danh sách hoàn tiền" handle={<Handles />}>
          <>
            <Search />
            <DataTable loading={loading} />
          </>
        </Section>
      </WrapRefund>
    </ErrorBound>
  );
}
export default memo(Refund);
