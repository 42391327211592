/*
 *
 * Withdrawal style css file
 * make by phamthainb
 */

import { text } from '@styles/mixins';
import theme from '@styles/theme';
import styled from 'styled-components';

const WrapWithdrawal = styled.div`
  .content-section {
    background: transparent;
    padding: 0;
  }
`;

export default WrapWithdrawal;

export const SWithdr = styled.div`
  .left {
    background: ${theme.color.rose_white};
    padding: 20px;
    border-radius: 12px;
    &:first-child {
      margin-bottom: 20px;
    }
    .title {
      font-family: ${theme.fonts['Inter-Regular']};
      font-size: 16px;
      line-height: 19px;
      color: ${theme.color.dark};
      background: ${theme.color.background};
      border: 0.8px solid #e6ebf1;
      border-radius: 12px;
      padding: 16px 20px;
      margin: 0;
      margin-bottom: 20px;
      .balance {
        font-family: ${theme.fonts['Inter-Medium']};
        ${text.xl}
        color: ${theme.color.blue_science};
      }
    }
    /* .money_number {
      font-family: ${theme.fonts['K2D-Light']};
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      color: #4a70ff;
      position: relative;
      & > input:first-child {
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        color: #4a70ff;
        height: 55px;
      }
      .unit {
        font-size: 20px;
        line-height: 23px;
        color: #5f6169;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1%;
        background: white;
        padding: 12px 22px;
      }
    } */
    .form-withdr {
      position: relative;

      .field-input {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-bottom: 16px;
        .text {
          color: ${theme.color.dark};
          ${text.sm}
          margin: 0 0 4px;
        }
        .infor_withdr {
          /* width: 67%; */
        }
      }
      .bt {
        position: absolute;
        right: 4px;
        height: 32px;
      }
      .final_button {
        width: 179px;
        margin: 0;
        margin-top: 20px;
      }
      .finalBT {
        position: relative;
      }
    }
  }
  .center {
    text-align: center;
    background: ${theme.color.rose_white};
    border-radius: 12px;
    display: grid;
    place-items: center;
    img {
      max-width: 331px;
      width: 100%;
    }
  }

  @media screen and (max-width: 991px) {
    .center {
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    .left {
      .form-withdr {
        .field-input {
          display: block;
          .infor_withdr {
            width: 100%;
          }
          .bt {
            top: 50%;
          }
        }
      }
    }
    .center {
      display: none;
    }
  }
`;

export const StyleModal1 = styled.div`
  text-align: center;
  margin-top: 41px;
  p {
    font-family: ${theme.fonts['K2D-Regular']};
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #3a3b42;
    padding-top: 28px;
  }
`;

export const StyleModal = styled.div`
  h3 {
    margin: unset;
    font-family: ${theme.fonts['K2D-Light']};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #3a3b42;
  }
  .form-data {
    .sendOTP {
      display: flex;
      justify-content: space-between;
      margin: 18px 0 30px;
      align-items: center;
      .field-input {
        display: flex;
        align-items: center;
        width: 83%;
        align-items: baseline;
        .text {
          margin-right: 33px;
        }
        .infor_withdr {
          input {
            /* width: 157%; */
          }
        }
      }
      .btt {
        font-family: ${theme.fonts['K2D-Light']};
        font-size: 16px;
        line-height: 24px;
        text-decoration-line: underline;
        color: #4a70ff;
        font-weight: unset;
        width: 86px;
        padding: unset;
        &:hover {
          all: unset;
        }
      }
    }

    .button {
      margin-top: 44px;
      display: flex;
      justify-content: flex-end;
      .bt {
        background: #1fd392;
        border-radius: 4px;
        width: 160px;
        height: 40px;
      }
      .cancel {
        background: #e4effa;
        margin-right: 16px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #6e7b97;
        font-family: ${theme.fonts['K2D-Light']};
      }
    }
    .resend {
      font-family: ${theme.fonts['K2D-Light']};
      font-size: 16px;
      line-height: 24px;
      color: #3a3b42;
      margin: 33px 0px 0;

      button {
        background: unset;
        border: unset;
        font-size: 17px;
        color: blue;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .form-data {
      .sendOTP {
        display: unset;
        .field-input {
          width: unset;
        }
      }
    }
  }
`;
