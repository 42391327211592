/*
 *
 * PaymentLinks style css file
 * make by phamthainb
 */

import styled from 'styled-components';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { SHandle as SHandleClone } from '@containers/TransactionList/style';

export const SHandle = styled(SHandleClone)`
  @media (max-width: 767px) {
    padding: 0;
    .select {
      label {
        display: none;
      }
    }
    .print,
    .add {
      margin-right: 10px;
    }
  }

  button {
    margin-left: 15px;
  }
  .add-new {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-right: 12px;
    }
  }
  position: static;
  margin: 16px 0 12px;
`;

const WrapPaymentLinks = styled.div`
  .pagination-container {
    padding: 16px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    label {
      color: #8e95a7;
    }
    .select {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
`;

export default WrapPaymentLinks;
