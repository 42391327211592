import Input from '@components/Input';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { requestToken } from '@api/axios';
import API_URL from '@api/url';
import { Modal } from '@components/index';
import { Props as ModalProps } from '@components/Modal';
import { convertDateWithTime, convertStatus, money } from '@helpers/format';
import Table, { STable } from '@components/Table';

type Props = {
  data: { [key in string]: any };
  modalProps: Omit<ModalProps, 'children'>;
};

export default function ModalDetails({ data, modalProps }: Props) {
  const [resData, setResData] = useState<any>();

  useEffect(() => {
    requestToken({
      method: 'GET',
      url: API_URL.ORDER.DETAILS(data.id),
    }).then(res => {
      setResData(res.data);
    });
    // .catch(HandleError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  let fee = (Number(resData?.transactionFee) * Number(resData?.money)) / 100;

  return (
    <Modal {...modalProps}>
      <SDetailPayment>
        {resData ? (
          <>
            <h2>Thông tin Khách hàng</h2>
            <Row>
              <Col lg={6} sm={12}>
                <div className="item">
                  <div className="text">Tên khách hàng</div>
                  <div className="value">
                    <SInput defaultValue={resData?.customerName} disabled />
                  </div>
                </div>
              </Col>
              <Col lg={6} sm={12}>
                <div className="item">
                  <div className="text">Số điện thoại</div>
                  <div className="value">
                    <SInput defaultValue={resData?.customerMobile} disabled />
                  </div>
                </div>
              </Col>
              <Col lg={6} sm={12}>
                <div className="item">
                  <div className="text">Ngày sinh</div>
                  <div className="value">
                    <SInput
                      defaultValue={
                        resData?.customerBirthday
                          ? new Date(
                              resData?.customerBirthday,
                            ).toLocaleDateString()
                          : ''
                      }
                      disabled
                    />
                  </div>
                </div>
              </Col>
              <Col lg={6} sm={12}>
                <div className="item">
                  <div className="text">Địa chỉ</div>
                  <div className="value">
                    <SInput defaultValue={resData?.customerAddress} disabled />
                  </div>
                </div>
              </Col>

              <Col lg={6} sm={12}>
                <div className="item">
                  <div className="text">Thời gian yêu cầu</div>
                  <div className="value">
                    <SInput
                      defaultValue={convertDateWithTime(resData?.createdAt)}
                      disabled
                    />
                  </div>
                </div>
              </Col>
              <Col lg={6} sm={12}>
                <div className="item">
                  <div className="text">Thời gian giao dịch</div>
                  <div className="value">
                    <SInput
                      defaultValue={
                        resData?.transactioncreatedAt
                          ? convertDateWithTime(resData?.transactioncreatedAt)
                          : 'Chưa thanh toán'
                      }
                      disabled
                    />
                  </div>
                </div>
              </Col>
              <Col lg={6} sm={12}>
                <div className="item">
                  <div className="text">Mã đơn hàng</div>
                  <div className="value">
                    <SInput defaultValue={resData?.codeBill} disabled />
                  </div>
                </div>
              </Col>
              <Col lg={6} sm={12}>
                <div className="item">
                  <div className="text">Trạng thái</div>
                  <div className="value">
                    <SInput
                      defaultValue={
                        resData?.transactionsStatus == null
                          ? 'Chưa thanh toán'
                          : convertStatus(resData?.transactionsStatus)
                      }
                      disabled
                    />
                  </div>
                </div>
              </Col>
              <Col lg={6} sm={12}>
                <div className="item">
                  <div className="text">Giảm giá</div>
                  <div className="value">
                    <SInput
                      defaultValue={money(
                        resData?.moneyDiscount,
                        undefined,
                        true,
                      )}
                      disabled
                    />
                  </div>
                </div>
              </Col>
              <Col lg={6} sm={12}>
                <div className="item">
                  <div className="text">Phí giao dịch</div>
                  <div className="value">
                    <SInput
                      defaultValue={money(fee, undefined, true)}
                      disabled
                    />
                  </div>
                </div>
              </Col>
              <Col lg={6} sm={12}>
                <div className="item">
                  <div className="text">
                    Tổng tiền khách hàng thanh toán đơn hàng
                  </div>
                  <div className="value">
                    <SInput
                      defaultValue={money(
                        resData?.transactionsMoney,
                        undefined,
                        true,
                      )}
                      disabled
                    />
                  </div>
                </div>
              </Col>
            </Row>

            <h2>Thông tin Sản phẩm</h2>
            <Row>
              <Table
                style={{ border: true, width: '100%' }}
                thead={['STT', 'Tên sản phẩm', 'Giá', 'Số lượng'].map(
                  (h, index) => (
                    <th key={'thead' + index}>{h}</th>
                  ),
                )}
                tbody={resData.product.map((d: any, k: number) => {
                  return (
                    <tr>
                      <td>{k + 1}</td>
                      <td>{d.nameProduct ?? ''}</td>
                      <td>{money(d.priceProduct ?? '', undefined, true)}</td>
                      <td>{d.quantity ?? ''}</td>
                    </tr>
                  );
                })}
              />
            </Row>
          </>
        ) : (
          'Không có thông tin.'
        )}
      </SDetailPayment>
    </Modal>
  );
}

const SDetailPayment = styled.div`
  .item {
    margin-bottom: 12px;
  }
  ${STable} {
    width: 100%;
  }
`;

const SInput = styled(Input)`
  &:disabled {
    background: white;
  }
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #3a3b42;
`;
