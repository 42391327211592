import { requestInterToken } from '@api/axios';
import API_URL from '@api/url';
import Print from '@assets/images/print-icon.svg';
import Button from '@components/Button';
import { HandleError } from '@components/HandleResponse';
import exportExcel from '@helpers/exportExcel';
import {
  clearParams,
  convertStatus,
  convertStatusWithDraw,
} from '@helpers/format';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectTransactionListStore } from './store/selecters';
import styled from 'styled-components';
import { mixinsFlexCenter } from '@styles/mixins';
import theme from '@styles/theme';

export default function Handle() {
  const { search, type } = useSelector(selectTransactionListStore);

  const onExport = async () => {
    // console.log('type', type);
    try {
      let dataExcel: any = [],
        name;
      if (type === 'plus') {
        name = 'Danh sách cộng tiền';
        dataExcel = (
          await requestInterToken({
            method: 'GET',
            url: API_URL.TRANSACTION.GET,
            params: clearParams({ ...search, listType: 'excel' }),
          })
        ).data.map((d: any, index: number) => ({
          STT: index + 1,
          'Mã đơn hàng': d?.paymentLink?.codeBill,
          'Số tiền cộng vào ví': d?.plusMoney || 0,
          'Số tiền thanh toán': d?.money,
          'Phí giao dịch': (d?.fee * d?.money) / 100 + d?.fixedFee || 0,
          'Thời gian yêu cầu': d?.createdAt,
          'Trạng thái': convertStatus(d?.status),
        }));
      } else {
        name = 'Danh sách rút tiền';
        dataExcel = (
          await requestInterToken({
            method: 'GET',
            url: API_URL.WITHDRAW.GET,
            params: clearParams({ ...search, listType: 'excel' }),
          })
        ).data.map((d: any, index: number) => ({
          STT: index + 1,
          'Mã giao dịch': d?.code,
          'Số tiền rút': d?.money,
          'Phí giao dịch': Number(d?.minusMoney) - Number(d?.money) || 0,
          'Thời gian yêu cầu': d?.dateRequest,
          'Trạng thái': convertStatusWithDraw(d?.status),
        }));
      }

      exportExcel(dataExcel, name);
    } catch (error) {
      HandleError(error);
    }
  };

  return (
    <SHandles>
      <Button className="print" onClick={onExport}>
        Export
      </Button>
    </SHandles>
  );
}

const SHandles = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 10px;
  margin: 16px 0 12px;
  .print {
    border: 2px solid #de58fd !important;
    background: #fff !important;
    margin-right: 32px;
    color: #de58fd !important;
  }
  .add {
    margin-right: 32px;
    ${mixinsFlexCenter};
    svg,
    img {
      margin-right: 14px;
    }
  }
  .select {
    ${mixinsFlexCenter};
    label {
      color: ${theme.color.text_phu};
    }
  }
  @media (max-width: 767px) {
    .select {
      label {
        display: none;
      }
    }
  }
`;
