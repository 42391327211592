import { money } from '@helpers/format';
import moment from 'moment';
import React from 'react';
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  AreaChart,
} from 'recharts';

interface Props {
  data: any;
  start: Date;
  end: Date;
}
function formatXAxis(tickItem: any) {
  return `Ngày ${moment(tickItem).format('DD/MM/YYYY')}`;
}
function formatXAxisShort(tickItem: any) {
  return moment(tickItem).format('DD/MM');
}
function formatYAxis(tickItem: any) {
  return money(tickItem, undefined, true);
}
function formatYAxisShort(tickItem: any) {
  return money(tickItem, 'VND');
}
export default function Chart({ data, start, end }: Props) {
  const [render, setRender] = React.useState({ data: [], type: '' });

  React.useEffect(() => {
    const result = data.map((item: any) => ({
      name: item?.date,
      value: Number(item?.money || 0),
    }));
    setRender({ ...render, data: result });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <ResponsiveContainer>
      <AreaChart
        width={500}
        height={500}
        data={render.data}
        syncId="anyId"
        margin={{
          top: 10,
          right: 30,
          left: 50,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip
          labelFormatter={formatXAxis}
          formatter={(value: any, name: any, props: any) => {
            return [formatYAxis(value), 'Giá trị'];
          }}
        />
        <YAxis tickFormatter={formatYAxisShort} />
        <XAxis dataKey="name" tickFormatter={formatXAxisShort} />
        <Area type="monotone" dataKey="value" stroke="#82ca9d" fill="#82ca9d" />
      </AreaChart>
    </ResponsiveContainer>
  );
}
