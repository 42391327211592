/*
 *
 * TransactionList style css file
 * make by phamthainb
 */

import { mixinsFlexCenter } from '@styles/mixins';
import theme from '@styles/theme';
import styled from 'styled-components';

const WrapTransactionList = styled.div`
  table {
    tr {
      text-align: left;
    }
  }
  .pagination-container {
    padding: 16px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    label {
      color: #8e95a7;
    }
    .select {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
`;

export const STabs = styled.div`
  margin-top: 16px;
  position: relative;
  @media (max-width: 1199px) {
    margin-top: 0;
  }
`;

export const SHandle = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 10px;
  position: absolute;
  right: 0px;
  top: 0;
  z-index: 1;
  .print {
    border: 1px solid #e3e8f0;
    background-color: white;
    margin-right: 32px;
  }
  .select {
    ${mixinsFlexCenter};
    label {
      color: ${theme.color.text_phu};
    }
  }
  @media (max-width: 1199px) {
    position: static;
    margin: 16px 0 12px;
  }
  @media (max-width: 767px) {
    .select {
      label {
        display: none;
      }
    }
  }
`;

export const SModal = styled.form`
  .payment-id {
    margin: 0 auto 32px;
    max-width: 80%;
    background-color: #e4effa;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 32px;
    span:nth-child(1) {
      color: ${theme.color.text_chinh};
    }
    span:nth-child(2) {
      color: ${theme.color.link};
      font-size: 20px;
      font-family: ${theme.fonts['K2D-Regular']};
      line-height: 24px;
    }
  }
  .modal-field {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 20px;
    span {
      display: inline-block;
      flex-wrap: wrap;
      color: ${theme.color.text_chinh};
      padding: 8px 16px;
    }
    span:nth-child(2) {
      border: 1px solid #e3e8f0;
      font-family: ${theme.fonts['K2D-Regular']};
    }
  }
  @media (max-width: 679px) {
    .payment-id {
      max-width: 100%;
      margin-bottom: 20px;
      padding: 10px;
      span:nth-child(2) {
        font-size: 16px;
      }
    }
    .modal-field {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
      margin-bottom: 5px;
      span {
        padding: 5px;
      }
      span:nth-child(1) {
        padding-left: 0;
      }
    }
    .payment-id span,
    .modal-field span {
      font-size: 14px;
    }
  }
`;

export const SSelectedTab = styled.div`
  padding: 20px 4px 52px;
  @media (max-width: 991px) {
    padding-bottom: 40px;
    padding-top: 0;
  }
  @media (max-width: 767px) {
    padding-bottom: 20px;
  }
`;

export default WrapTransactionList;
