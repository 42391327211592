import ShowSelect from '@components/Input/ShowSelect';
import Table from '@components/Table';
import { changePage } from '@containers/PaymentLinks/store/actions';
import { convertDateWithTime, convertStatus } from '@helpers/format';
import Pagination from 'rc-pagination';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectRefundStore } from './store/selecters';
const THead = [
  'Mã giao dịch',
  'Mã đơn hàng',
  'Trạng thái',
  'Lý do yêu cầu',
  'Thời gian',
];

export default function DataTable({ loading }: { loading: boolean }) {
  const { data, pagination, total } = useSelector(selectRefundStore);
  const dispatch = useDispatch();

  return (
    <>
      <Table
        style={{ border: true }}
        isLoading={loading}
        thead={THead.map((h, index) => (
          <th key={'thead' + index}>{h}</th>
        ))}
        tbody={data.map((d, index) => {
          return (
            <tr key={'tr' + index}>
              <td>{d?.code}</td>
              <td>{d?.transaction?.paymentLink?.codeBill}</td>
              <td>{convertStatus(d?.status)}</td>
              <td>{d?.content}</td>
              <td>{convertDateWithTime(d?.createdAt)}</td>
            </tr>
          );
        })}
      />
      {(total || 0) / pagination.pageSize >= 1 && (
        <div className="pagination-container">
          <div className="select">
            <label>
              Hiển thị từ{' '}
              {`${
                (pagination.pageNumber - 1) * pagination.pageSize + 1
              } đến ${Math.ceil(
                pagination.pageSize * pagination.pageNumber,
              )}`}{' '}
              trên tổng số {total}
            </label>
            <ShowSelect
              defaultValue={{
                label: pagination.pageSize.toString(),
                value: pagination.pageSize.toString(),
              }}
              onChange={val => {
                let pageSize = pagination.pageSize;
                if (val && parseInt(val.value) !== pageSize) {
                  pageSize = parseInt(val.value);
                  dispatch(changePage({ pageNumber: 1, pageSize }));
                }
              }}
            />
          </div>

          <Pagination
            onChange={(pageNumber: number, pageSize: number) => {
              dispatch(changePage({ pageNumber, pageSize }));
            }}
            pageSize={pagination.pageSize}
            current={pagination.pageNumber}
            total={total}
          />
        </div>
      )}
    </>
  );
}
