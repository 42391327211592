import React, { InputHTMLAttributes, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import Input from './index';
import { text } from '@styles/mixins';
import theme from '@styles/theme';
import { ReactComponent as Search } from '@assets/images/mobeos/home/search.svg';
import { ReactComponent as Eye } from '@assets/images/mobeos/home/eye.svg';
import { ReactComponent as EyeOff } from '@assets/images/mobeos/home/eye_off.svg';

type Props = {
  size?: 'default' | 'small';
  label?: string;
  search?: boolean;
  inputRef?:
    | ((instance: HTMLInputElement | null) => void)
    | React.RefObject<HTMLInputElement>
    | null
    | undefined;
  type?: string;
  required?: boolean;
} & InputHTMLAttributes<HTMLInputElement> &
  React.RefAttributes<HTMLInputElement>;

const CustomInput = ({
  size,
  label,
  search,
  inputRef,
  type,
  required,
  ...props
}: Props) => {
  const [showPassword, setShowPassword] = React.useState(false);
  return (
    <CustomInputStyled size={size} label={!!label} search={search}>
      {label && <span>{label}</span>}
      {label && required && <span className="required"> *</span>}
      <div className="input-container">
        {search && <Search className="icon" />}
        <Input {...props} ref={inputRef} type={showPassword ? 'text' : type} />
        {type === 'password' &&
          (showPassword ? (
            <Eye
              onClick={() => setShowPassword(!showPassword)}
              className="password"
            />
          ) : (
            <EyeOff
              onClick={() => setShowPassword(!showPassword)}
              className="password"
            />
          ))}
      </div>
    </CustomInputStyled>
  );
};
CustomInput.defaultProps = {
  size: 'default',
  type: 'text',
  required: true,
};

const CustomInputStyled = styled.div<{
  size?: 'default' | 'small';
  label: boolean;
  search?: boolean;
}>`
  flex: 1;

  .input-container {
    position: relative;
  }

  ${({ search }) =>
    search &&
    css`
      .input-container {
        position: relative;
        .icon {
          position: absolute;
          left: 16px;
          top: 50%;
          transform: translateY(-50%);
          width: 34px;
          height: 34px;
        }
      }
    `}

  ${Input} {
    border: 0.8px solid #a5adc2;
    border-radius: 12px;
    height: 48px;
    padding: 12px 16px;
    ${text.base};
    color: ${theme.color.dark};
    transition: transform 0.3s ease;
    margin-top: ${props => (props.label ? '4px' : '0px')};
    padding-left: ${props => (props.search ? '58px' : '')};

    ${({ size }) =>
      size === 'small' &&
      css`
        height: 40px;
        border-radius: 8px;
        ${text.sm};
        padding: 10px 16px;
      `}

    &:focus {
      border-color: ${theme.color.blue_science};
    }
    &:disabled {
      background: #f6f7fb;
      color: ${theme.color.cadet_blue};
    }
  }

  span {
    ${text.sm};
    color: ${theme.color.dark};
    font-family: ${theme.fonts['Inter-Regular']};
    font-weight: 400;
  }

  .password {
    position: absolute;
    top: calc(50% + 4px);
    transform: translateY(-50%);
    right: 10px;
    background: #fff;
  }
  .required {
    color: ${theme.color.red};
  }
`;

export default CustomInput;
