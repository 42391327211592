import Modal from '@components/Modal';
import Table from '@components/Table';
import {
  convertDate,
  convertDateWithTime,
  convertStatus,
} from '@helpers/format';
import React, { useState } from 'react';
import { SModal } from './style';
import * as format from '@helpers/format';

export default function DataTable({
  data,
  loading,
}: {
  data: any[];
  loading: boolean;
}) {
  const [detail, setDetail] = useState<any | null>(null);

  const THead = [
    'Mã đơn hàng',
    'Số tiền cộng vào ví',
    'Số tiền thanh toán',
    'Phí giao dịch',
    'Luồng tiền',
    'Thời gian yêu cầu',
    'Trạng thái',
  ];

  return (
    <>
      <Modal
        isShowing={detail ? true : false}
        title="Chi tiết giao dịch"
        toggleModal={() => setDetail(null)}
        overwriteChild={{ style: { maxWidth: '720px' } }}
      >
        <SModal>
          <div className="payment-id">
            <span>Đơn hàng</span>
            <span>{detail?.paymentLink?.url}</span>
          </div>
          <div className="modal-field">
            <span>Mã đơn hàng</span>
            <span>{detail?.paymentLink?.codeBill}</span>
          </div>
          <div className="modal-field">
            <span>Số tiền cộng vào ví</span>
            <span>
              {detail?.plusMoney
                ? format.money(detail?.plusMoney || 0, undefined, true)
                : 0}
            </span>
          </div>
          <div className="modal-field">
            <span>Số tiền</span>
            <span>{format.money(detail?.money, undefined, true)}</span>
          </div>
          <div className="modal-field">
            <span>Hình thức</span>
            <span>{detail?.gateway}</span>
          </div>
          <div className="modal-field">
            <span>Phí giao dịch</span>
            <span>
              {format.money(
                (detail?.fee * detail?.money) / 100 + detail?.fixedFee || 0,
                undefined,
                true,
              )}
            </span>
          </div>
          {!detail?.gateway && (
            <>
              <div className="modal-field">
                <span>Chủ tài khoản</span>
                <span>{detail?.holder}</span>
              </div>
              <div className="modal-field">
                <span>Ngân hàng</span>
                <span>{detail?.bank}</span>
              </div>
            </>
          )}
          {/* <div className="modal-field">
            <span>Thời gian giao dịch</span>
            <span>
              {detail?.dateConfirm && convertDateWithTime(detail?.dateConfirm)}
            </span>
          </div> */}
          <div className="modal-field">
            <span>Thời gian yêu cầu</span>
            <span>{convertDateWithTime(detail?.createdAt)}</span>
          </div>
          <div className="modal-field">
            <span>Trạng thái</span>
            <span>{convertStatus(detail?.status)}</span>
          </div>
          <div className="modal-field">
            <span>Ghi chú</span>
            <span>{detail?.note || ''}</span>
          </div>
        </SModal>
      </Modal>
      <Table
        isLoading={loading}
        style={{ border: false, minWidth: 900 }}
        thead={THead.map((h, index) => (
          <th key={'thead' + index}>{h}</th>
        ))}
        tbody={data.map(d => {
          return (
            <tr
              onClick={() => setDetail({ ...d })}
              style={{ userSelect: 'none', cursor: 'pointer' }}
            >
              <td>{d?.paymentLink?.codeBill}</td>
              <td>
                {d?.plusMoney
                  ? format.money(d?.plusMoney || 0, undefined, true)
                  : null}
              </td>
              <td>{format.money(d?.money, undefined, true)}</td>
              <td>
                {format.money(
                  (d?.fee * d?.money) / 100 + d?.fixedFee || 0,
                  undefined,
                  true,
                )}
              </td>
              <td>{d?.gateway === 'mastercard' ? 'Quốc tế' : 'Nội địa'}</td>
              <td>{convertDate(d?.createdAt)}</td>
              <td>
                <span className={d?.status}>{convertStatus(d?.status)}</span>
              </td>
            </tr>
          );
        })}
      />
    </>
  );
}
