import { convertStatus, money } from '@helpers/format';
import React, { useState } from 'react';
import ModalDetailz from './ModalDetailz';

export default function DataTableRow({ product }: { product: any }) {
  const [isOpen, setIsOpen] = useState(false);
  let fee = (Number(product?.transactionFee) * Number(product?.money)) / 100;
  return (
    <tr
      key={product.id}
      onClick={() => {
        setIsOpen(true);
      }}
    >
      <td style={{ cursor: 'pointer' }}>{product?.codeBill}</td>
      <td title={product?.createdAt}>
        {new Date(product?.createdAt).toLocaleDateString('vi-VN')}
      </td>
      <td>{money(product?.moneyDiscount, undefined, true)}</td>
      <td>{money(fee, undefined, true)}</td>
      <td>{money(product?.transactionsMoney, undefined, true)}</td>
      <td>
        <span
          className={
            product?.transactionsStatus == null
              ? 'unused'
              : product?.transactionsStatus
          }
        >
          {product?.transactionsStatus == null
            ? 'Chưa thanh toán'
            : convertStatus(product?.transactionsStatus)}
        </span>
      </td>
      {isOpen && (
        <ModalDetailz
          data={product}
          modalProps={{
            isShowing: isOpen,
            toggleModal: () => {
              setIsOpen(!isOpen);
            },
            title: 'Chi tiết Đơn hàng',
          }}
        />
      )}
    </tr>
  );
}
