/*
 *
 * Order style css file
 * make by phamthainb
 */

import styled from 'styled-components';
import { mixinsFlexCenter } from '@styles/mixins';
import theme from '@styles/theme';

const WrapOrder = styled.div`
  table {
    tr {
      text-align: left;
    }
  }
  .pagination-container {
    padding: 16px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    label {
      color: #8e95a7;
    }
    .select {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
`;

export const SHandle = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 16px;
  margin: 16px 0 12px;
  .print {
    border: 1px solid #e3e8f0;
    background-color: white;
    margin-right: 32px;
  }
  .add {
    margin-right: 16px;
    ${mixinsFlexCenter};
    font-weight: normal;
    svg,
    img {
      margin-right: 14px;
    }
  }
  .select {
    ${mixinsFlexCenter};
    label {
      color: ${theme.color.text_phu};
    }
  }
  @media (max-width: 767px) {
    padding: 0;
    .select {
      label {
        display: none;
      }
    }
    .print,
    .add {
      margin-right: 10px;
    }
  }
`;

export default WrapOrder;
