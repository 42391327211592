import Print from '@assets/images/print-icon.svg';
import Button from '@components/Button';
import React from 'react';
import { SHandles } from './style';

import { HandleError } from '@components/HandleResponse';
import FileSaver from 'file-saver';
import { useSelector } from 'react-redux';
import { handleExport } from './store/actions';
import { selectRefundStore } from './store/selecters';

export default function Handles() {
  const { search } = useSelector(selectRefundStore);

  const onExport = () => {
    handleExport({
      ...search,
    })
      .then(res => {
        FileSaver.saveAs(res.data, 'paymentlink.xlsx');
      })
      .catch(HandleError);
  };

  return (
    <SHandles>
      <Button className="print" onClick={onExport}>
        Export
      </Button>
    </SHandles>
  );
}
