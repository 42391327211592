import React from 'react';
import moment from 'moment';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import styled from 'styled-components';
import theme from '@styles/theme';

export default class DatePickerMultiple extends React.Component {
  constructor(props) {
    super(props);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);

    this.state = {
      from: this.props.start,
      to: this.props.end,
    };
  }

  showFromMonth() {
    const { from, to } = this.state;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
    this.props.setData(this.state.from, this.state.to);
  }

  handleFromChange(from) {
    this.setState({ from });
  }

  handleToChange(to) {
    this.setState({ to }, this.showFromMonth);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // console.log('nesProps', nextProps);
    this.setState({
      from: nextProps.start,
      to: nextProps.end,
    });
  }

  render() {
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };

    // console.log(this.props);
    return (
      <StyleDateMul>
        <div className="InputFromTo">
          <DayPickerInput
            value={from ? moment(from).format('DD/MM/YYYY') : ''}
            placeholder="Từ"
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              disabledDays: { after: to },
              toMonth: to,
              modifiers,
              numberOfMonths: 2,
              onDayClick: () => this.to.getInput().focus(),
            }}
            onDayChange={this.handleFromChange}
          />
          <span className="spread"> - </span>
          <DayPickerInput
            ref={el => (this.to = el)}
            value={to ? moment(to).format('DD/MM/YYYY') : ''}
            placeholder="Đến"
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              disabledDays: { before: from },
              modifiers,
              month: from,
              fromMonth: from,
              numberOfMonths: 2,
            }}
            onDayChange={this.handleToChange}
          />
          <IconDatePicker />
        </div>
      </StyleDateMul>
    );
  }
}

const IconDatePicker = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 15.0387L6.34616 9.38488L7.39999 8.33105L12 12.9311L16.6 8.33105L17.6538 9.38488L12 15.0387Z"
      fill="#A5ADC2"
    />
  </svg>
);

const StyleDateMul = styled.div`
  /* .spread {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding-bottom: 3px;
  } */

  .InputFromTo {
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(
        .DayPicker-Day--end
      ):not(.DayPicker-Day--outside) {
      background-color: #f0f8ff !important;
      color: #4a90e2;
    }
    .DayPicker-Day {
      border-radius: 0 !important;
    }
    .DayPicker-Day--start {
      border-top-left-radius: 50% !important;
      border-bottom-left-radius: 50% !important;
    }
    .DayPicker-Day--end {
      border-top-right-radius: 50% !important;
      border-bottom-right-radius: 50% !important;
    }
    .DayPickerInput-Overlay {
      width: 600px;
      margin-left: -198px;
    }

    @media (max-width: 991px) {
      .DayPickerInput-Overlay {
        width: 350px;
        margin-left: -198px;
      }
      /* responsive day-picker     */
      /* .DayPickerInput-OverlayWrapper {
        background-color: red;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
      } */
    }
  }

  .InputFromTo {
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    position: relative;
    padding: 10px 16px;
    padding-right: 60px;
    border: 0.8px solid ${theme.color.cadet_blue};
    box-sizing: border-box;
    border-radius: 8px;
    background: #f6f7fb;

    svg,
    img {
      position: absolute;
      content: '';
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
    input {
      width: 80px;
      padding: 0;
      margin: 0;
      border: 0;
      text-align: center;
      height: 100%;
      outline: none;
      background: transparent;
      font-size: 14px;
      line-height: 20px;

      /* text phu */

      color: ${theme.color.dark};
    }
  }

  .DayPickerInput {
    display: inline-block;
    height: 100%;
  }
`;
