/* eslint-disable @typescript-eslint/no-unused-vars */
import { requestToken } from '@api/axios';
import Button from '@components/Button';
import { HandleError } from '@components/HandleResponse';
import DatePickerMultiple from '@components/Input/DatePickerMultiple.js';
import { stylesCustom } from '@components/Input/SelectBase';
import { mixinsFlexCenter } from '@styles/mixins';
import theme from '@styles/theme';
import moment, { unitOfTime } from 'moment';
import React, {
  HTMLAttributes,
  ReactChild,
  ReactChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import Chart from '.';
import Select from '@components/Input/Select';
interface Props extends HTMLAttributes<any> {
  title?: any;
  children?: ReactChild | ReactChildren;
  url: string;
}

const heightStyles = {
  height: '32px',
  minHeight: '32px',
};

const SelectStyle = {
  ...stylesCustom(heightStyles),
  valueContainer: (provided: any) => ({
    ...provided,
    ...heightStyles,
    width: '100px',
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 20,
    top: '35px',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontStyle: 'normal',
    fontWight: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    textAlign: 'center',
    color: '#5F6169',
  }),
};

const optionsSelect = [
  { label: '---', value: '' },
  { label: 'Tuần nay', value: 'week' },
  { label: 'Tháng nay', value: 'month' },
  { label: 'Năm nay', value: 'year' },
];

export default function BoxChart({ title, children, url, ...props }: Props) {
  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      start: new Date(),
      end: new Date(),
    },
  });

  const [option, setOption] = useState<string | null | undefined>(null);
  const [data, setDataChart] = useState<{ [key in string]: any }[]>([]);

  const onSumit = ({ start, end }: { start: any; end: any }) => {
    getData(start, end);
    // console.log('submit');

    // setOption('custom');
  };

  const getData = useCallback((start, end) => {
    const startDate = moment(start).format('YYYY-MM-DD 00:00:01');
    const endDate = moment(end).format('YYYY-MM-DD 23:59:59');
    // console.log(getBwtDay(start));

    requestToken({
      method: 'GET',
      url: url,
      params: {
        startDate,
        endDate,
      },
    })
      .then(res => {
        setDataChart(res.data);
        // console.log(res.data);
      })
      .catch(HandleError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log(watch());

  useEffect(() => {
    // register('options');
    register('start', { required: true });
    register('end', { required: true });
    // get default
    getData(new Date(), new Date());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setData = (from: any, to: any) => {
    setValue('start', from);
    setValue('end', to);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const prosTypeChart = useMemo(() => option, [data]);

  return (
    <SBoxChart {...props}>
      {title && (
        <div className="title-wrap">
          <h3 className="title">{title}</h3>
          <form className="search" onSubmit={handleSubmit(onSumit)}>
            <div className="search-item">

            <Select
              // className="search-item"
              value={optionsSelect?.filter(i => i.value === option)}
              // styles={SelectStyle}
              // defaultInputValue={'Hôm nay'}
              styles={{
                height: '40px',
                minHeight: '40px',
              }}
              options={optionsSelect}
              onChange={props => {
                if (props?.value) {
                  const startOfMonth = moment()
                    .clone()
                    .startOf(props?.value as unitOfTime.StartOf)
                    .format('YYYY-MM-DD hh:mm');
                  const endOfMonth = moment()
                    .clone()
                    .endOf(props?.value as unitOfTime.StartOf)
                    .format('YYYY-MM-DD hh:mm');
                  // console.log(startOfMonth, endOfMonth);
                  setData(new Date(startOfMonth), new Date(endOfMonth));
                } else setData(null, null);
                setOption(props?.value);
              }}
            />
            </div>
            <DatePickerMultiple
              className="search-item"
              setData={(from: any, to: any) => {
                setData(from, to);
                setOption('custom');
              }}
              start={watch('start')}
              end={watch('end')}
            />
            <Button
              type="submit"
              className="search-item"
              style={{ height: '40px', margin: 0 }}
            >
              Lọc
            </Button>
          </form>
          {/* <span className="mark" /> */}
        </div>
      )}
      <div className="content">
        {/* <Chart data={data} /> */}
        {data.length > 0 ? (
          <Chart data={data} start={watch('start')} end={watch('end')} />
        ) : (
          <div className="nothing-data">Chưa có dữ liệu</div>
        )}
      </div>
    </SBoxChart>
  );
}

export const SBoxChart = styled.div`
  /* background: #ffffff; */
  border-radius: 8px;
  padding: 32px 25px;
  position: relative;
  margin: 16px 0 32px 0;
  padding-top: 26px;

  .title-wrap {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .search {
      display: flex;
      gap: 15px;
      .search-item {
        width: 150px;
      }
    }

    h3.title {
      font-size: 24px;
      line-height: 36px;
      color: ${theme.color.dark};
      margin: 0;
    }

    /* .mark {
      height: 1px;
      width: calc(100% + 50px);
      background: #e3e8f0;
      display: block;
      transform: translateX(-25px);
      position: absolute;
      bottom: 0;
    } */
  }

  .content,
  .recharts-responsive-container {
    min-height: 300px;
    background: ${theme.color.rose_white};
    border-radius: 12px;
  }

  .nothing-data {
    padding-top: 32px;
    ${mixinsFlexCenter};
  }

  @media (max-width: 1199px) {
    margin: 0;
  }
  @media (max-width: 767px) {
    .title-wrap {
      flex-direction: column;
      padding-bottom: 24px;

      .search {
        width: 100%;
        justify-content: center;
        flex-direction: column;
        gap: 20px !important;

        .search-item {
          /* margin: 12px 0; */
          flex-flow: 1;
          width: 100% !important;
        }
        .InputFromTo {
          max-width: 100%;
        }
      }
    }
  }
`;
