/*
 *
 * Dashboard
 * make by phamthainb
 */

import ErrorBound from '@components/ErrorBound';
import useInjectReducer from '@redux/useInjectReducer';
import React, { memo, useEffect, useMemo, useState } from 'react';
import reducersDashboard from './store/reducers';
import WrapDashboard, { SAnalyticsItem } from './style';

// import img, icon
// import IHome from '@assets/images/icon-home.svg';
import ICelendar from '@assets/images/celendar_blue.svg';
import IAnalytics1 from '@assets/images/icon-analytics-1.svg';
import IAnalytics2 from '@assets/images/icon-analytics-2.svg';
import IAnalytics3 from '@assets/images/icon-analytics-3.svg';
import IAnalytics4 from '@assets/images/icon-analytics-4.svg';
import IAnalytics5 from '@assets/images/icon-analytics-5.svg';
import IAnalytics6 from '@assets/images/icon-analytics-6.svg';
// import IMyLink from '@assets/images/my-link.svg';
// import IArrowRight from '@assets/images/arrow-right-blue.svg';
// import IEyeClose from '@assets/images/close_eye.svg';
// import IEyeOpen from '@assets/images/open_eye.svg';

import API_URL from '@api/url';
import Modal from '@components/Modal';
import { selectAppStore } from '@containers/App/store/selecters';
import * as format from '@helpers/format';
import useMediaQuery from '@hooks/useMediaQuery';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import BoxChart from './Chart/BoxChart';
import LinkShop from './LinkShop';
import LinkStatistic from './LinkStatistic';
import { disStatistic } from './store/actions';
import { selectDashboardStore } from './store/selecters';

interface Props {}

// eslint-disable-next-line
function Dashboard({}: Props) {
  useInjectReducer('Dashboard', reducersDashboard);
  const [isShowing, toggleModal] = useState(false);
  const { statistic } = useSelector(selectDashboardStore);
  const { user } = useSelector(selectAppStore);
  const dispatch = useDispatch();
  const { width } = useMediaQuery();

  useEffect(() => {
    dispatch(disStatistic());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const valueStatistic = useMemo(() => {
    const {
      totalTransaction,
      totalTransactionGlobal,
      totalWithdraw,
      totalWithdrawGlobal,
    } = statistic;
    const wallet = user?.wallet ?? {};

    return [
      {
        name: 'Số dư ví thẻ nội địa',
        value: wallet[0]?.money ?? 0,
        color: '#0B0C17',
        border: '#F5C51B',
        icon: () => <img src={IAnalytics4} alt="icon" />,
      },
      {
        name: 'Tổng tiền thanh toán thẻ nội địa',
        value: totalTransaction ?? 0,
        color: 'linear-gradient(180deg, #4AB5FF 0%, #4A70FF 100%)',
        border: '#4AB5FF',
        icon: () => <img src={IAnalytics5} alt="icon" />,
      },
      {
        name: 'Số tiền đã rút từ thẻ nội địa',
        value: totalWithdraw ?? 0,
        color: 'linear-gradient(180deg, #59E26A 0%, #4ED1A7 100%)',
        border: '#59E26A',
        icon: () => <img src={IAnalytics6} alt="icon" />,
      },
      {
        name: 'Số dư ví thẻ quốc tế',
        value: wallet[1]?.money ?? 0,
        color: 'linear-gradient(180deg, #F5C51B 0%, #FF793F 100%)',
        border: '#F5C51B',
        icon: () => <img src={IAnalytics1} alt="icon" />,
      },
      {
        name: 'Tổng tiền thanh toán thẻ quốc tế',
        value: totalTransactionGlobal ?? 0,
        color: 'linear-gradient(180deg, #4AB5FF 0%, #4A70FF 100%)',
        border: '#4AB5FF',
        icon: () => <img src={IAnalytics2} alt="icon" />,
      },
      {
        name: 'Số tiền đã rút từ thẻ quốc tế',
        value: totalWithdrawGlobal ?? 0,
        color: 'linear-gradient(180deg, #59E26A 0%, #4ED1A7 100%)',
        border: '#59E26A',
        icon: () => <img src={IAnalytics3} alt="icon" />,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statistic]);

  return (
    <ErrorBound>
      <WrapDashboard>
        <div className="grid-container">
          <div id="dashboard-top">
            <div id="dashboard-top__value">
              {valueStatistic.map((item: any, index: number) => {
                return (
                  <SAnalyticsItem
                    key={index}
                    bgr={item.color}
                    border={item.border}
                  >
                    <span className="icon">{item.icon()}</span>
                    <div className="right-container">
                      <p className="text">{item.name}</p>
                      <p className="value">
                        {format.money(item.value || 0, undefined, true)}
                      </p>
                    </div>
                  </SAnalyticsItem>
                );
              })}
            </div>

            {/* <div
              id="dashboard-top__link"
              onClick={() => toggleModal(!isShowing)}
              style={{ cursor: 'pointer' }}
            >
              <div className="link_icon_text">
                <div className="link_icon">
                  <img src={IMyLink} alt="icon" />
                </div>
                <p className="link_text">Link của tôi</p>
              </div>
              <div className="link_arrow">
                <img src={IArrowRight} alt="icon" />
              </div>
            </div> */}
          </div>

          <div id="chart">
            <BoxChart
              url={API_URL.DASHBOARD.TRANSACTION_SUCCESS}
              title="Giao dịch thành công"
            />
          </div>
          <div id="right">
            <div id="dashboard-top__title">
              <div className="celendar">
                <img src={ICelendar} alt="icon" />
              </div>
              <div className="time">
                <p className="today">Hôm nay</p>
                <p
                  className="date-time"
                  style={{ textTransform: 'capitalize' }}
                >
                  {moment().format('dddd, Do MMMM YYYY')}
                </p>
              </div>
            </div>
            <LinkStatistic />
            {/* {width >= 1200 && <JoinNow />} */}
          </div>
        </div>
      </WrapDashboard>

      {/* modal */}
      <Modal
        title="Chia sẻ link nhận thanh toán"
        isShowing={isShowing}
        toggleModal={toggleModal}
        overwriteChild={{ style: { maxWidth: '820px' } }}
      >
        <LinkShop />
      </Modal>
    </ErrorBound>
  );
}

export default memo(Dashboard);
