import { mixinsFlexCenter } from './../../../styles/mixins';
/*
 *
 * Refund style css file
 * make by phamthainb
 */

import styled from 'styled-components';
import Button from '@components/Button';
import theme from '@styles/theme';

const WrapRefund = styled.div`
  table {
    tr {
      text-align: left;
    }
  }
  .pagination-container {
    padding: 16px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    label {
      color: #8e95a7;
    }
    .select {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
`;

export const SSearch = styled.div`
  padding: 0 10px;
  .field-input {
    margin-bottom: 15px;
    display: block;

    .text {
      margin: 4px 0;
      font-size: 16px;
      line-height: 19px;
      color: ${theme.color.text_phu};
    }
    .input {
    }
  }

  .btn-submit {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 15px;

    ${Button} {
      max-width: 200px;
      width: 100%;
    }
    @media (max-width: 575px) {
      ${Button} {
        max-width: unset;
        width: 100%;
      }
    }
  }
`;

export const SHandles = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 10px;
  margin: 16px 0 12px;
  .print {
    border: 2px solid #de58fd !important;
    background: #fff !important;
    margin-right: 32px;
    color: #de58fd !important;
  }
  .add {
    margin-right: 32px;
    ${mixinsFlexCenter};
    svg,
    img {
      margin-right: 14px;
    }
  }
  .select {
    ${mixinsFlexCenter};
    label {
      color: ${theme.color.text_phu};
    }
  }
  @media (max-width: 767px) {
    .select {
      label {
        display: none;
      }
    }
  }
`;

// export const SHandleModal =

export default WrapRefund;
