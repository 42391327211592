import { Alert } from '@components/Alert';
import Input, { Checkbox, DatePicker } from '@components/Input';
import theme from '@styles/theme';
import React, { SetStateAction, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Col, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import IInforClient from '@assets/images/infor_client.svg';
import { requestInterToken, requestToken } from '@api/axios';
import API_URL from '@api/url';
import { HandleError, HandleSuccess } from '@components/HandleResponse';
import { Controller, useForm } from 'react-hook-form';
import { Button, Text } from '@components/index';
import VALIDATE from '@helpers/validate';
import { ErrorMessage } from '@hookform/error-message';
import { convertDateWithTime, convertExpireDate, money } from '@helpers/format';
type Props = {
  data: { [key in string]: any };
  changeStatus: any;
  toggle: SetStateAction<any>;
};

const keys = [
  { name: 'Mục đích thanh toán', key: 'paymentPurpose' },
  { name: 'Tình trạng', key: 'status' },
  { name: 'Số tiền', key: 'money' },
  { name: 'Đơn vị ', key: 'currency' },
  { name: 'Số tiền thanh toán', key: 'transactionsMoney' },
  { name: 'Mã hóa đơn', key: 'codeBill' },
  { name: 'Người tạo', key: 'merchant' },
  { name: 'Thời gian tạo', key: 'createdAt', type: 'date' },
  { name: 'Thời gian kích hoạt', key: 'updatedAt', type: 'date' },
  { name: 'Ngày hết hạn', key: 'expireDate' },
  { name: 'Ghi chú', key: 'note' },
];

const keyinfor = [
  { name: 'Tên  khách hàng', key: 'userName' },
  { name: 'Số điện thoại', key: 'mobile' },
  { name: 'Ngày sinh', key: 'birthday' },
  { name: 'Địa chỉ', key: 'address' },
  // { name: 'Tên sản phẩm', key: 'codeProduct' },
  { name: 'Đơn hàng', key: 'link' },
];

export default function DetailPayment({ data, changeStatus, toggle }: Props) {
  const [customerInfo, setcustomerInfo] = useState<any>();
  const { register, handleSubmit, errors, control, setValue } = useForm();
  const [nameMerchant, setnameMerchant] = useState('');

  useEffect(() => {
    requestToken({
      method: 'GET',
      url: API_URL.PAYMENTLINK.GET_ID(data.id),
    }).then(res => {
      const customerInfo = JSON.parse(res.data.customerInfo) || {};
      if (customerInfo['birthday']) {
        setValue('birthday', new Date(customerInfo['birthday']));
      }

      setcustomerInfo(customerInfo);

      let name = `${res.data.merchant.firstName} ${res.data.merchant.lastName} (Bạn)`;
      if (res.data.paymentLinkType === 'fixed') {
        name = customerInfo.userName;
      }
      setnameMerchant(name);
    });
    // .catch(HandleError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const obSumit = (dataForm: any) => {
    // console.log('dataForm', dataForm);
    requestInterToken({
      method: 'PUT',
      url: `${API_URL.CUSTOMER.PUT}/${customerInfo.id}`,
      data: {
        urlPaymentLink: data?.url,
        userName: dataForm?.userName,
        mobile: dataForm?.mobile,
        birthday: dataForm?.birthday && new Date(dataForm?.birthday),
        address: dataForm?.address,
        codeProduct: dataForm?.codeProduct,
      },
    })
      .then(HandleSuccess)
      .catch(HandleError);
  };

  return (
    <SDetailPayment>
      {data ? (
        <div className="content-container">
          <p className="title">Copy link</p>
          <CopyToClipboard
            text={`${window.location.origin}/payment/${data?.url}`}
            onCopy={() => Alert({ icon: 'success', name: 'Đã sao chép.' })}
          >
            <div>
              <h2 className="link">
                {`${window.location.origin}/payment/${data?.url}`}{' '}
                <CopyLinkSVG />
              </h2>
            </div>
          </CopyToClipboard>
          <div className="list">
            <Row>
              {keys.map((k, index) => {
                if (k.key === 'status') {
                  return (
                    <Col sm={6}>
                      <div className="item" key={index}>
                        <div className="text">
                          {k.name} <span>(Vô hiệu hóa Đơn hàng)</span>
                        </div>
                        <SInputStatus>
                          <span>Tình trạng</span>
                          <Checkbox
                            onClick={() => changeStatus()}
                            checked={data[k.key] === 'active' ? true : false}
                          />
                        </SInputStatus>
                      </div>
                    </Col>
                  );
                } else if (k.key === 'merchant') {
                  return (
                    <Col sm={6}>
                      <div className="item">
                        <div className="text">{k.name}</div>
                        <div className="value">
                          <SInput defaultValue={nameMerchant} disabled />
                        </div>
                      </div>
                    </Col>
                  );
                } else if (k.type === 'date') {
                  return (
                    <Col sm={6}>
                      <div className="item">
                        <div className="text">{k.name}</div>
                        <div className="value">
                          <SInput
                            defaultValue={convertDateWithTime(data[k.key])}
                            disabled
                          />
                        </div>
                      </div>
                    </Col>
                  );
                } else if (k.key === 'expireDate') {
                  return (
                    <Col sm={6}>
                      <div className="item">
                        <div className="text">{k.name}</div>
                        <div className="value">
                          <SInput
                            defaultValue={
                              data?.notExpire
                                ? 'Không hết hạn'
                                : convertExpireDate(data[k.key])
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </Col>
                  );
                } else if (k.key === 'money' || k.key === 'transactionsMoney') {
                  return (
                    <Col sm={6}>
                      <div className="item">
                        <div className="text">{k.name}</div>
                        <div className="value">
                          <SInput
                            defaultValue={money(data[k.key], undefined, true)}
                            disabled
                          />
                        </div>
                      </div>
                    </Col>
                  );
                } else if (k.key === 'note')
                  return (
                    <Col>
                      <div className="item">
                        <div className="text">{k.name}</div>
                        <div className="value">
                          <SInput defaultValue={data[k.key]} />
                        </div>
                      </div>
                    </Col>
                  );
                return (
                  <Col sm={6}>
                    <div className="item">
                      <div className="text">{k.name}</div>
                      <div className="value">
                        <SInput defaultValue={data[k.key]} disabled />
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
          {/* Thông tin khách hàng */}
          <div className="info">
            <div className="title">
              <img src={IInforClient} alt="icon" />
              <p>Thông tin khách hàng</p>
            </div>

            <div className="list">
              <form onSubmit={handleSubmit(obSumit)}>
                <Row>
                  {keyinfor.map((k, index) => {
                    if (k.key === 'link') {
                      return (
                        <Col key={index} sm={6}>
                          <div className="item">
                            <div className="text">{k.name}</div>
                            <div className="value">
                              <SInput defaultValue={data?.url} disabled />
                            </div>
                          </div>
                        </Col>
                      );
                    } else if (k.key === 'birthday') {
                      return (
                        <Col key={index} sm={6}>
                          <div className="item">
                            <div className="text">{k.name}</div>
                            <div className="value">
                              <Controller
                                control={control}
                                name={k.key}
                                render={props => (
                                  <DatePicker
                                    {...props}
                                    onDayChange={props.onChange}
                                  />
                                )}
                              />
                            </div>
                            <ErrorMessage
                              errors={errors}
                              name={k.key}
                              render={Text.ErrorMessage}
                            />
                          </div>
                        </Col>
                      );
                    } else if (k.key === 'userName') {
                      return (
                        <Col key={index} sm={6}>
                          <div className="item">
                            <div className="text">{k.name}</div>
                            <div className="value">
                              <SInput
                                ref={register({
                                  required: VALIDATE.REQUIRE,
                                  maxLength: {
                                    value: 255,
                                    message: 'Tối đa 255 ký tự.',
                                  },
                                })}
                                name={k.key}
                                defaultValue={
                                  customerInfo ? customerInfo[k.key] : ''
                                }
                              />
                            </div>
                            <ErrorMessage
                              errors={errors}
                              name={k.key}
                              render={Text.ErrorMessage}
                            />
                          </div>
                        </Col>
                      );
                    } else if (k.key === 'codeProduct') {
                      return (
                        <Col key={index} sm={6}>
                          <div className="item">
                            <div className="text">{k.name}</div>
                            <div className="value">
                              <SInput
                                ref={register({
                                  required: false,
                                })}
                                name={k.key}
                                defaultValue={
                                  customerInfo ? customerInfo[k.key] : ''
                                }
                              />
                            </div>
                            <ErrorMessage
                              errors={errors}
                              name={k.key}
                              render={Text.ErrorMessage}
                            />
                          </div>
                        </Col>
                      );
                    }
                    return (
                      <Col key={index} sm={6}>
                        <div className="item">
                          <div className="text">{k.name}</div>
                          <div className="value">
                            <SInput
                              ref={register({
                                required: VALIDATE.REQUIRE,
                                maxLength: {
                                  value: 255,
                                  message: 'Tối đa 255 ký tự.',
                                },
                                minLength: {
                                  value: 6,
                                  message: 'Tối thiểu 6 ký tự.',
                                },
                              })}
                              name={k.key}
                              defaultValue={
                                customerInfo ? customerInfo[k.key] : ''
                              }
                            />
                          </div>
                          <ErrorMessage
                            errors={errors}
                            name={k.key}
                            render={Text.ErrorMessage}
                          />
                        </div>
                      </Col>
                    );
                  })}
                </Row>
                <div className="btn-container">
                  <Button
                    className="btn-cancel"
                    onClick={() => {
                      toggle();
                    }}
                    style={{ margin: 'unset' }}
                  >
                    Hủy bỏ
                  </Button>
                  <Button type="submit" style={{ margin: 'unset' }}>
                    Cập nhật
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        'Không có thông tin.'
      )}
    </SDetailPayment>
  );
}

const SDetailPayment = styled.div`
  .content-container {
    .title {
      font-size: 14px;
      color: #0b0c17;
    }
    .btn-container {
      margin-top: 40px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }
    .btn-cancel {
      border: 2px solid #ccd6e2;
      background: #fff;
      color: #484c8b;
    }
  }
  .link {
    border-radius: 12px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border: 1px solid #0075df;
    font-style: normal;
    font-family: ${theme.fonts['K2D-Regular']};
    font-size: 20px;
    line-height: 23px;
    color: #4a70ff;
    word-break: break-all;
    min-height: 52px;
    height: unset;
    padding: 8px;
  }

  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 32px;

    .item {
      /* display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 450px;
        width: 100%; */
      margin-top: 18px;

      .text {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #3a3b42;
        margin-bottom: 4px;
      }
      .value {
        input {
          font-family: ${theme.fonts['K2D-Regular']};
          font-size: 16px;
          line-height: 19px;
          /* identical to box height */

          /* text chinh */

          color: #3a3b42;
        }
      }
    }
  }

  .info {
    margin-top: 56px;
    .title {
      display: flex;
      align-items: center;
      p {
        font-family: ${theme.fonts['Roboto-Bold']};
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        margin-left: 8px;
        color: #3a3b42;
      }
    }
    .list {
      margin-top: 13px;
    }
  }
  @media (max-width: 767px) {
    .link {
      word-break: break-all;
      min-height: 52px;
      padding: 15px;
      font-size: 15px;
    }
    .list {
      .item {
        flex-direction: column;
        .text {
          align-items: flex-start;
          width: 100%;
          padding-bottom: 16px;
        }
      }
    }
  }
`;

const SInput = styled(Input)`
  &:disabled {
    background: #a5adc213;
    color: #a5adc2 !important;
    font-weight: 500;
  }
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #3a3b42;
`;

const SInputStatus = styled.div`
  display: flex;
  border: 1px solid #e3e8f0;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  min-height: 48px;
  height: 48px;
  border: 1px solid #a5adc2;
  align-items: center;
  padding: 0 15px;
  /* max-width: 236px; */
  justify-content: space-between;

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #0b0c17;
  }
`;

const CopyLinkSVG = () => (
  <svg
    width="26"
    height="24"
    viewBox="0 0 26 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.05775 17.5C8.55258 17.5 8.125 17.325 7.775 16.975C7.425 16.625 7.25 16.1974 7.25 15.6923V4.30775C7.25 3.80258 7.425 3.375 7.775 3.025C8.125 2.675 8.55258 2.5 9.05775 2.5H17.4423C17.9474 2.5 18.375 2.675 18.725 3.025C19.075 3.375 19.25 3.80258 19.25 4.30775V15.6923C19.25 16.1974 19.075 16.625 18.725 16.975C18.375 17.325 17.9474 17.5 17.4423 17.5H9.05775ZM9.05775 16H17.4423C17.5193 16 17.5898 15.9679 17.6538 15.9038C17.7179 15.8398 17.75 15.7693 17.75 15.6923V4.30775C17.75 4.23075 17.7179 4.16025 17.6538 4.09625C17.5898 4.03208 17.5193 4 17.4423 4H9.05775C8.98075 4 8.91025 4.03208 8.84625 4.09625C8.78208 4.16025 8.75 4.23075 8.75 4.30775V15.6923C8.75 15.7693 8.78208 15.8398 8.84625 15.9038C8.91025 15.9679 8.98075 16 9.05775 16ZM5.55775 21C5.05258 21 4.625 20.825 4.275 20.475C3.925 20.125 3.75 19.6974 3.75 19.1923V6.30775H5.25V19.1923C5.25 19.2693 5.28208 19.3398 5.34625 19.4038C5.41025 19.4679 5.48075 19.5 5.55775 19.5H15.4423V21H5.55775Z"
      fill="#0075DF"
    />
  </svg>
);
